<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      page_title="course"
      :item_no="item_no"
      :item_btn_add="true"
      :btn_text="'addnew'"
      @ShowAction="ShowAction"
    />
    <v-card class="mx-3 my-5" style="border-radius: 16px">
      <v-container>
        <v-row justify="space-between" class="ma-1">
          <v-col cols="12" sm="10" xs="12">
            <v-row>
              <v-col cols="12" xs="12" sm="4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  style="border-radius: 8px; border-color: #c0c0c0"
                  clearable
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                  v-model="search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="8">
                <v-btn
                  v-if="selectManyCourseStatus"
                  :disabled="disableUserSelect"
                  class="text-capitalize ml-2"
                  :class="$vuetify.breakpoint.width < 376 ? 'mb-1' : 'mb-0'"
                  style="
                    font-size: 16px;
                    background: #67ab30;
                    border-radius: 8px;
                    box-shadow: unset;
                    color: #ffffff;
                    margin-top: 3px;
                  "
                  @click="openUserListDialog()"
                  >{{ $t("selectusers") }}</v-btn
                >
                <v-btn
                  v-if="selectManyCourseStatus"
                  class="text-capitalize ml-2"
                  style="
                    font-size: 16px;
                    background: #67ab30;
                    border-radius: 8px;
                    box-shadow: unset;
                    color: #ffffff;
                    margin-top: 3px;
                  "
                  @click="exportExcel()"
                  >{{ $t("exportexcel") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"            
            sm="2"
            xs="12"
            style="text-align: right !important"
          >
            <v-btn
              class="text-capitalize"
              style="
                color: #424242;
                font-size: 16px;
                border: 1px solid #a6cc39;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              @click="showFilterTools = !showFilterTools"
            >
              <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
              {{ $t("filter") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showFilterTools" class="ma-1">
          <v-col cols="12" xs="12" sm="6" lg="2">
            <label
              for="createdatefrom"
              style="
                font-size: 16px;
                color: #424242;
                font-weight: bold;
                margin-top: 5px;
              "
              >{{ $t("createdatefrom") }}</label
            >
            <v-menu
              v-model="frommenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromdate"
                  prepend-inner-icon="mdi-calendar-blank-outline"
                  outlined
                  dense
                  readonly
                  hide-details
                  placeholder="yyyy-mm-dd"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromdate"
                @input="startDateDisabled"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-divider inset style="border-color: #424242"></v-divider>
          <v-col cols="12" xs="12" sm="6" lg="2">
            <label
              for="to"
              style="
                font-size: 16px;
                color: #424242;
                font-weight: bold;
                margin-top: 5px;
              "
              >{{ $t("to") }}</label
            >
            <v-menu
              v-model="tomenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="todate_"
                  prepend-inner-icon="mdi-calendar-blank-outline"
                  outlined
                  dense
                  readonly
                  hide-details
                  v-bind="attrs"
                  placeholder="yyyy-mm-dd"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="todate_"
                @input="endDateDisabled(), (tomenu = false)"
                :allowed-dates="allowedDatesEnds"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="2">
            <label
              for="startdate"
              style="
                font-size: 16px;
                color: #424242;
                font-weight: bold;
                margin-top: 5px;
              "
              >{{ $t("startdate") }}</label
            >
            <v-menu
              v-model="frommenu_New"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startdate_New"
                  prepend-inner-icon="mdi-calendar-blank-outline"
                  outlined
                  dense
                  readonly
                  hide-details
                  v-bind="attrs"
                  placeholder="yyyy-mm-dd"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startdate_New"
                @input="StartDateDisabled_New(), (frommenu_New = false)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-divider inset style="border-color: #424242"></v-divider>
          <v-col cols="12" xs="12" sm="6" lg="2">
            <label
              for="enddate"
              style="
                font-size: 16px;
                color: #424242;
                font-weight: bold;
                margin-top: 5px;
              "
              >{{ $t("enddate") }}</label
            >
            <v-menu
              v-model="tomenu_New"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="enddate_New"
                  prepend-inner-icon="mdi-calendar-blank-outline"
                  outlined
                  dense
                  readonly
                  hide-details
                  v-bind="attrs"
                  placeholder="yyyy-mm-dd"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="enddate_New"
                @input="tomenu_New = false"
                :allowed-dates="allowedEndDates_New"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="4" style="padding-top: 2.2rem">
            <v-btn
              text
              class="text-capitalize"
              height="40"
              style="
                border: 1px solid #c0c0c0;
                border-radius: 8px;
                color: #424242;
                font-size: 16px;
              "
              @click="clearAll()"
              >{{ $t("clearall") }}</v-btn
            >
            <v-btn
              min-width="120"
              height="40"
              class="text-capitalize btn_hover_effect ml-4"
              style="
                color: #ffff;
                border: 1px solid #a6cc39;
                border-radius: 8px;
                font-size: 16px;
              "
              color="#A6CC39"
              @click="OnChangeSDate()"
              >{{ $t("search") }}</v-btn
            >
          </v-col>
        </v-row>

        <v-row class="ma-1">
          <v-col>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="items"
              hide-default-footer
              class="assingnment-course"
              :search="search"
              @page-count="pageCount = $event"
              :page.sync="page"
              multiple-select
              :show-select="selectManyCourseStatus"
              v-model="$store.state.assignmentUser.selectedCourses"
              @toggle-select-all="getSelectAll"
              :items-per-page="itemsPerPage"
            >
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.courseName="{ item }">
                <p
                  style="
                    width: 300px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  class="mb-0"
                >
                  {{ item.courseName }}
                </p>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.description="{ item }">
                <p
                  style="
                    width: 250px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  class="mb-0"
                  v-for="(subItem, i) in item.videoList"
                  :key="i"
                >
                  {{ i + 1 + "." }}{{ subItem.videoName }}
                </p>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.quizName="{ item }">
                <div v-if="item.quizList?.length !== 0">
                  <p
                    style="word-break: break-all; width: 250px"
                    class="mb-0"
                    v-for="(subItem, j) in item.quizList"
                    :key="j"
                  >
                    {{ subItem.quizName }}
                    {{
                      subItem.quizTypeID
                        ? " ( " + $t(quizTypes[subItem.quizTypeID]) + " )"
                        : ""
                    }}
                  </p>
                </div>
                <div v-else style="display: flex; justify-content: left">
                  {{ "-" }}
                </div>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.actions="{ item }">
                <div class="d-flex" style="margin-top: -5px">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text v-bind="attrs" v-on="on" class="editbtn">
                        <v-icon style="color: #424242"
                          >mdi-dots-vertical</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list class="d-flex flex-column align-start">
                      <v-list-item class="list-item-padding">
                        <v-btn
                          color="#424242"
                          style="
                            font-size: 16px;
                            width: 100px;
                            justify-content: left;
                          "
                          text
                          class="text-capitalize"
                          @click="editassignmentcourse(item)"
                        >
                          <v-icon left>mdi-square-edit-outline</v-icon>
                          {{ $t("edit") }}
                        </v-btn>
                      </v-list-item>
                      <v-list-item class="list-item-padding">
                        <v-btn
                          color="#FF6060"
                          style="
                            font-size: 16px;
                            width: 100px;
                            justify-content: left;
                          "
                          text
                          class="text-capitalize"
                          @click="checkAssignmentDelete(item)"
                        >
                          <v-icon left>mdi-delete-outline</v-icon>
                          {{ $t("delete") }}
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- New Pagination Design -->
        <v-row>
          <v-col cols="12" xs="12" sm="9" md="9" xl="10">
            <div class="d-flex text-left pl-1">
              <v-pagination
                circle
                v-model="page"
                class="pagination"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            sm="3"
            md="3"
            xl="2"
            class="d-flex justify-end"
          >
            <p class="mt-3 pr-4">{{ $t("Show") }}</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="70"
                  v-bind="attrs"
                  v-on="on"
                  style="border: 1px solid #c0c0c0; opacity: 1"
                  class="text-capitalize mt-1 mr-3"
                  outlined
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in paginationCount"
                  :key="index"
                  @click="itemsPerPage = item.title"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog
      v-model="openAddDialog"
      persistent
      scrollable
      max-width="1229px"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title style="font-size: 25px; height: 80px">
          <v-icon style="color: #363636" class="mr-1"
            >mdi-plus-box-outline</v-icon
          >
          {{ $t("addcourse") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="cancelAssignmentCourse()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 800px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
                <v-row>
                  <v-col cols="12">
                    <p class="mb-1" style="color: #424242; font-size: 16px">
                      {{ $t("createdateM") }}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ this.createdate }} {{ $t("By") }} {{ this.full_Name }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <label for="title" class="font-style">{{
                      $t("title")
                    }}</label>
                    <v-text-field
                      v-model="$store.state.assignmentUser.assignmentCourseName"
                      :rules="courseRules"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="description" class="font-style">{{
                      $t("description")
                    }}</label>
                    <v-text-field
                      v-model="
                        $store.state.assignmentUser.assignmentCourseDescription
                      "
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
                <v-row>
                  <v-col>
                    <p
                      class="text-left font-style mb-1"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div class="drop mt-2">
                      <v-card
                        width="270"
                        height="200"
                        style="
                          border: 2px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.courseFile.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropCourseImg($event)"
                      >
                        <v-img
                          :src="$store.state.assignmentUser.courseImgFile"
                          @click="$refs.courseFile.click()"
                          @mouseover="
                            $store.state.assignmentUser.courseImgFile
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            $store.state.assignmentUser.courseImgFile
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="200"
                          height="155"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="$store.state.assignmentUser.courseImgFile"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#FF6060"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="
                                    ($store.state.assignmentUser.courseImgFile =
                                      null),
                                      (courseFile = null),
                                      ($store.state.assignmentUser.fileNameOfCourseImg =
                                        null)
                                  "
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>
                        <v-img
                          v-if="!$store.state.assignmentUser.courseImgFile"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.courseFile.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="courseFile"
                          accept="image/*"
                          @change="uploadCourseImg($event, 1)"
                        />
                        <p
                          v-show="!$store.state.assignmentUser.courseImgFile"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!$store.state.assignmentUser.courseImgFile"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          font-size: 16px !important;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.courseFile.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p
                        class="mt-3 pr-3"
                        style="color: #777777; font-size: 12px"
                      >
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3">
                <label for="startdate" class="font-style">{{
                  $t("startdate")
                }}</label>
                <v-menu
                  v-model="startdatemenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="$store.state.assignmentUser.startdate"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="$store.state.assignmentUser.startdate"
                    @input="newstartDateDis"
                    :allowed-dates="newallowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-progress-circular
                v-if="actionloading"
                indeterminate
                class="loading-circle"
                color="green"
              ></v-progress-circular>
              <v-col cols="12" sm="6" md="4" lg="3">
                <div class="d-inline">
                  <label for="enddate" class="font-style">{{
                    $t("enddate")
                  }}</label>
                  <v-menu
                    v-model="enddatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="$store.state.assignmentUser.enddate"
                        prepend-inner-icon="mdi-calendar-blank-outline"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="$store.state.assignmentUser.enddate"
                      @input="enddatemenu = false"
                      :allowed-dates="newendallowedDates"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col>
                <label for="status" class="font-style">{{
                  $t("status")
                }}</label>
                <v-radio-group
                  row
                  v-model="$store.state.assignmentUser.status"
                  class="activation pa-2"
                  hide-details
                >
                  <v-radio
                    :label="$t('active')"
                    value="1"
                    color="#67AB30"
                    @click="disableNoti($store.state.assignmentUser.status)"
                  ></v-radio>
                  <v-radio
                    class="ml-10"
                    :label="$t('inactive')"
                    value="0"
                    color="#67AB30"
                    @click="disableNoti($store.state.assignmentUser.status)"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <div class="onoffswitch mt-4 ml-4">
                  <input
                    type="checkbox"
                    name="onoffswitch"
                    class="onoffswitch-checkbox"
                    id="myonoffswitch"
                    v-model="$store.state.assignmentUser.viewerstatus"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                  />
                  <label class="onoffswitch-label" for="myonoffswitch">
                    <div class="onoffswitch-inner"></div>
                    <div class="onoffswitch-switch"></div>
                  </label>
                </div>
                <div class="classdivider">
                  <v-divider
                    vertical
                    style="
                      border-color: #424242;
                      opacity: 0.2;
                      margin-left: 6.5rem;
                      margin-top: -6rem;
                      min-height: 4em;
                    "
                  ></v-divider>
                </div>

                <div class="labelswitch">
                  <label
                    for="viewersettings"
                    style="font-size: 16px; color: #000000; font-weight: bold"
                    >{{ $t("viewersettings") }}
                  </label>
                </div>
                <div class="labelswitchs">
                  <label for="allowviewersrewind" style="font-size: 14px">
                    {{ $t("allowviewersrewind") }}
                  </label>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col>
                <div class="text-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- Add Course's -->
                      <v-btn
                        depressed
                        color="secondary"
                        style="font-size: 16px; margin-left: 15px; float: right"
                        class="d-flex select text-capitalize btn_hover_effect"
                        @click="addNew()"
                      >
                        <v-icon style="font-size: 16px; margin: 0px 5px 1px 0px"
                          >mdi-plus-box-outline</v-icon
                        >
                        {{ $t("addvideo") }}
                      </v-btn>

                      <v-btn
                        depressed
                        class="d-flex select btn_hover_effect"
                        style="font-size: 16px; float: right"
                        color="secondary"
                        dark
                        @click="ShowVideoListDialog = true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <!-- addassignmentCourse() -->
                        <p class="text-capitalize mb-0">
                          <v-icon class="pb-1"
                            >mdi-folder-search-outline</v-icon
                          >
                          {{ $t("fromlibrary") }}
                        </p>
                      </v-btn>
                    </template>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="libraryandpcheaders"
                :items="editVideoList"
                hide-default-footer
                class="assign-tablepc"
                disable-pagination
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-xs-start" style="width: 90px">
                      {{ item.part }}
                    </td>
                    <td class="text-xs-center">
                      <p
                        style="
                          width: 250px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.title }}
                      </p>
                    </td>
                    <td class="text-xs-start">
                      <p
                        style="
                          width: 250px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.videoName }}
                      </p>
                    </td>
                    <td class="text-xs-center">{{ item.size }}</td>

                    <td class="text-xs-center">{{ item.duration }}</td>

                    <td>
                      <div class="d-flex">
                        <!-- Add Course's video table edit !!! -->
                        <v-btn
                          color="#4FB14E"
                          text
                          style="font-size: 16px"
                          class="text-capitalize"
                          @click="editForVideo(item)"
                        >
                          <v-icon left>mdi-square-edit-outline</v-icon>
                          {{ $t("edit") }}
                        </v-btn>
                        <!-- editformlibraryandpc(item), -->
                        <v-btn
                          color="#FF6060"
                          text
                          style="font-size: 16px"
                          class="text-capitalize"
                          @click="checkVideolistDelete(item.no)"
                        >
                          <v-icon>mdi-delete-outline</v-icon>
                          {{ $t("delete") }}
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="mt-5">
              <p style="color: #424242; font-size: 16px; padding: 10px">
                {{ $t("sendnotito") }}
              </p>
              <v-row class="ml-6 mr-15" rows="12" sm="12" md="12" lg="12">
              <v-col cols="2">
                <v-card width="100px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                        >
                <v-checkbox
                  class="pb-5"
                  v-model="$store.state.assignmentUser.inbox"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :label="$t('inbox')"
                    color="#a6cc39"
                    hide-details
                ></v-checkbox
              ></v-card>
            </v-col>
              <v-col cols="3"
                >
                <v-card width="200px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                        >
                <v-checkbox
                    class="pb-5"
                    v-model="$store.state.assignmentUser.pushnoti"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    color="#a6cc39"
                   
                    :label="$t('pushnotification')"
                    hide-details
                ></v-checkbox
              ></v-card>
            </v-col>
              
              <v-col cols="2">
                <v-card width="100px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                        >
                <v-checkbox
                 class="pb-5"
                 v-model="$store.state.assignmentUser.line"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    color="#a6cc39"
                    :label="$t('line')"
                    hide-details
                ></v-checkbox>
              </v-card>
              </v-col>
              <v-col
              class="d-flex justify-center"
              >
                <v-card
                  width="250px"
                  height="35px"
                  style="
                    border: 1.5px dashed #c0c0c0;
                    box-shadow: unset;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                <v-row
                    class="d-flex align-center"
                    no-gutters
                  >
                    <v-col class="d-flex justify-center align-center" cols="4">
                      <v-checkbox
                        class="pb-5"
                        v-model="$store.state.assignmentUser.email"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    color="#a6cc39"
                    :label="$t('email')"
                    hide-details
                        @change="handleCheckboxChange"
                      ></v-checkbox>
                    </v-col>
                    <template>
                      <v-col class="d-flex justify-center align-center">
                        <v-row class="d-flex justify-center align-center">
                          <v-radio-group v-model="$store.state.assignmentUser.selectedRadio" class="radio-group" :disable="!$store.state.assignmentUser.email">
                            <v-row rows="12" class="radiobutton">
                              <div class="radio-wrapper">
                             
                             <div v-if="!$store.state.assignmentUser.email" class="radio-placeholder"></div>
                            
                             <v-radio
                               v-else
                               value="thai"
                               class="radio-button"
                             ></v-radio>
                           </div>
                           <v-img
                              src="@/assets/thailandflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            <div class="radio-wrapper ml-2">
                             
                             <div v-if="!$store.state.assignmentUser.email" class="radio-placeholder"> </div>
                            <v-radio
                            v-else
                            class=" radio-button"
                             value="eng"
                            
                              >
                            </v-radio>
                            </div>
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            </v-row>
                            
                          </v-radio-group>
                         
                        </v-row>
                      </v-col>
                    </template>
                   
                    <!-- <template>
                      <v-col class="d-flex justify-center align-center">
                        <v-row class="d-flex justify-center align-center">
                          <v-radio-group v-model="$store.state.assignmentUser.selectedRadio"
                          :indeterminate="
                            $store.state.assignmentUser.status == 0 ? true : false
                          "
                          :disabled="
                            $store.state.assignmentUser.status == 0 ? true : false
                          "
                           class="radio-group">
                            <div class="radio-wrapper">
                             
                              <div v-if="!$store.state.assignmentUser.email" class="radio-placeholder"></div>
                             
                              <v-radio
                                v-else
                                value="eng"
                                class="radio-button"
                              ></v-radio>
                            </div>
                          </v-radio-group>
                          <v-card
                            width="35px"
                            height="22px"
                            style="border-radius: 4px; margin-left: 8px;"
                          >
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                            ></v-img>
                          </v-card>
                        </v-row>
                      </v-col>
                    </template> -->
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            </div>

            <!-- Add Quiz in Add Assignment -->
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  depressed
                  color="secondary"
                  style="font-size: 16px; margin-top: 13px; float: right"
                  class="text-capitalize d-flex select white--text btn_hover_effect"
                  @click="OpenQuizDialog()"
                  :disabled="addQuizDisabled"
                >
                  <v-icon style="font-size: 16px; margin: 0px 5px 1px 0px"
                    >mdi-plus-box-outline</v-icon
                  >
                  {{ $t("addquiz") }}
                </v-btn>
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="quizheaders"
                :items="$store.state.assignmentUser.selectedListQuiz"
                hide-default-footer
                class="assign-table"
                @page-count="actionpageCount = $event"
                :page.sync="actionpage"
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.part="{ index }">{{
                  index + 1
                }}</template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.quizTypeName="{ item }">{{
                  $t(item.quizTypeName.toLowerCase().replace(/[-\/\s]/g, ""))
                }}</template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="#FF6060"
                    text
                    style="font-size: 16px"
                    class="text-capitalize"
                    @click="DeleteQuiz(item)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                    {{ $t("delete") }}
                  </v-btn>
                </template>
              </v-data-table>
            </div>

            <!-- Add Article in Add Assignment -->
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  depressed
                  color="secondary"
                  style="font-size: 16px; margin-top: 13px; float: right"
                  class="text-capitalize d-flex select white--text btn_hover_effect"
                  @click="AddArticleDialog = true"
                >
                  <v-icon style="font-size: 16px; margin: 0px 5px 1px 0px"
                    >mdi-plus-box-outline</v-icon
                  >
                  {{ $t("addarticle") }}
                </v-btn>
                <v-btn
                  depressed
                  class="d-flex select btn_hover_effect mr-4"
                  style="font-size: 16px; float: right; margin-top: 13px"
                  color="secondary"
                  dark
                  @click="ShowArticleListDialog = true"
                >
                  <p class="text-capitalize mb-0">
                    <v-icon class="pb-1">mdi-folder-search-outline</v-icon>
                    {{ $t("fromlibrary") }}
                  </p>
                </v-btn>
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="ArticleHeaders"
                :items="articleItem"
                hide-default-footer
                class="assign-table"
                @page-count="actionpageCount = $event"
                :page.sync="actionpage"
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.part="{ index }">{{
                  index + 1
                }}</template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="#4FB14E"
                    text
                    style="font-size: 16px"
                    class="text-capitalize"
                    @click="OpenEditArticleDialog(item)"
                  >
                    <v-icon>mdi-square-edit-outline</v-icon>
                    {{ $t("edit") }}
                  </v-btn>

                  <v-btn
                    color="#FF6060"
                    text
                    style="font-size: 16px"
                    class="text-capitalize"
                    @click="prepareArticleRemove(item)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                    {{ $t("delete") }}
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <!-- End Add Article -->

            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  depressed
                  color="secondary"
                  style="font-size: 16px; margin-top: 13px; float: right"
                  class="text-capitalize white--text select btn_hover_effect"
                  @click="$router.push('/home/assignment-course/user-list')"
                  >{{ $t("selecteduser") }}</v-btn
                >
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="userheaders"
                :items="selectedList"
                hide-default-footer
                class="assign-table user_tableSelected"
                :search="search"
                @page-count="actionpageCount = $event"
                :page.sync="actionpage"
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <div>
                    <v-btn
                      color="#FF6060"
                      text
                      style="font-size: 16px"
                      class="text-capitalize"
                      @click="checkUserDelete(item)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                      {{ $t("delete") }}
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
              <v-row justify="end">
                <div class="ma-2 d-flex">
                  <v-pagination
                    circle
                    v-model="actionpage"
                    class="pagination"
                    :length="actionpageCount"
                  ></v-pagination>
                </div>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-5 mt-3">
          <v-spacer></v-spacer>
          <p
            v-if="isValidate"
            style="font-size: 18px; color: red; margin-bottom: -2px"
          >
            Please upload video*&nbsp;&nbsp;&nbsp;
          </p>
          <v-btn
            outlined
            style="width: 100px"
            class="text-capitalize btnfont-style"
            text
            @click="cancelAssignmentCourse()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style white--text select btn_hover_effect"
            color="secondary"
            style="width: 100px"
            :disabled="btnEnable"
            @click="saveAssignmentCourse()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="viewassignmentcoursedialog"
      persistent
      max-width="1229px"
      :retain-focus="false"
      scrollable
    >
      <v-card>
        <v-card-title
          style="font-size: 30px; height: 80px"
          class="secondary white--text"
          >Assignment Course</v-card-title
        >
        <v-card-text>
          <v-form ref="form1" v-model="valid" lazy-validation>
            <v-row class="pt-4">
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  color="#4FB14E"
                  style="font-size: 20px; float: right; width: 130px"
                  class="text-capitalize white--text"
                  @click="editassignmentcourse(), (Enable = !Enable)"
                  >{{ $t("edit") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="8">
                <label for="coursename" class="font-style">
                  {{ $t("coursename") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  v-model="$store.state.assignmentUser.assignmentCourseName"
                  :disabled="Enable"
                  :rules="courseRules"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label for="startdate" class="font-style">{{
                  $t("startdate")
                }}</label>
                <v-menu
                  v-model="editstartdatemenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="$store.state.assignmentUser.editstartdate"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="$store.state.assignmentUser.editstartdate"
                    @input="editstartDateDis"
                    :allowed-dates="startallowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="d-inline">
                  <label for="enddate" class="font-style">{{
                    $t("enddate")
                  }}</label>
                  <v-menu
                    v-model="editenddatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="$store.state.assignmentUser.editenddate"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="$store.state.assignmentUser.editenddate"
                      @input="editenddatemenu = false"
                      :allowed-dates="endallowedDates"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  v-model="$store.state.assignmentUser.mushwatchOrder"
                  label="Must Watch In Order"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  v-model="$store.state.assignmentUser.prohiForward"
                  label="Prohibit Forward"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="font-style">{{ $t("videolist") }}</p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div class="text-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="d-flex"
                        style="
                          font-size: 20px;
                          padding-top: 15px;
                          float: right;
                          opacity: 0.5;
                          cursor: default;
                        "
                        color="#6CC8A0"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <p class="text-capitalize">
                          {{ $t("upload") }}
                          <v-icon style="margin-top: -4px"
                            >mdi-menu-down</v-icon
                          >
                        </p>
                      </v-btn>
                    </template>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="libraryandpcheaders"
                :items="$store.state.assignmentUser.pcVideoData"
                hide-default-footer
                class="assign-table"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-xs-start">{{ item.part }}</td>
                    <td class="text-xs-center">
                      <p
                        class="mt-3"
                        style="
                          width: 200px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.videoName }}
                      </p>
                    </td>
                    <td class="text-xs-start">
                      <p
                        class="mt-3"
                        style="
                          width: 200px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.videoFile }}
                      </p>
                    </td>
                    <td class="text-xs-start">
                      <p style="width: 200px" class="mt-3">{{ item.size }}</p>
                    </td>

                    <td class="text-xs-start">
                      <p class="mt-3">{{ item.duration }}</p>
                    </td>
                    <td>
                      <div class="d-flex">
                        <v-btn
                          color="#4FB14E"
                          text
                          style="font-size: 20px; color: #6cc8a0 !important"
                          class="text-capitalize"
                          disabled
                        >
                          <v-icon left>mdi-square-edit-outline</v-icon>
                          {{ $t("edit") }}
                        </v-btn>
                        <v-btn
                          color="#FF6060"
                          text
                          style="font-size: 20px; color: #fa5252 !important"
                          class="text-capitalize"
                          disabled
                        >
                          <v-icon>mdi-delete-outline</v-icon>
                          {{ $t("delete") }}
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <v-row no-gutters>
              <v-col>
                <p class="font-style">{{ $t("user") }}</p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  disabled
                  style="
                    opacity: 0.5;
                    color: #ffffff !important;
                    background-color: #6cc8a0 !important;
                    font-size: 20px;
                    margin-top: 13px;
                    float: right;
                  "
                  class="text-capitalize"
                  >Update User</v-btn
                >
              </v-col>
            </v-row>
            <div class="mt-4">
              <!-- {{selectedList}} -->
              <v-data-table
                :headers="userheaders"
                :items="selectedList"
                hide-default-footer
                class="assign-table"
                :search="search"
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{}">
                  <div class="d-flex">
                    <v-btn
                      color="#FF6060"
                      text
                      style="font-size: 20px; color: #fa5252 !important"
                      class="text-capitalize"
                      disabled
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                      {{ $t("delete") }}
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-5 mt-3">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            text
            @click="cancelAssignmentCourse()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize select font-style btn_hover_effect"
            style="
              width: 139px;
              opacity: 0.5;
              color: #ffffff !important;
              background-color: #6cc8a0 !important;
            "
            @click="save()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="openEditDialog"
      persistent
      max-width="1229px"
      :retain-focus="false"
      scrollable
    >
      <v-card>
        <v-card-title style="font-size: 25px; height: 80px">
          <v-icon color="#424242" class="mr-2">mdi-square-edit-outline</v-icon>
          {{ $t("editcourse") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              $store.commit('closeAssignmentEditDialog'),
                cancelAssignmentCourse()
            "
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 800px">
          <v-form ref="form2" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
                <v-row>
                  <v-col cols="12">
                    <p style="font-size: 16px; color: #47484b" class="mb-2">
                      {{
                        $t("createdateM")
                      }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ $store.state.assignmentUser.createDate }}
                      {{ $t("createby") }}
                      {{ $store.state.assignmentUser.createBy }}&nbsp;&nbsp;
                    </p>
                    <p
                      style="font-size: 16px; color: #47484b"
                      v-if="$store.state.assignmentUser.updateBy != ''"
                    >
                      {{
                        $t("Last Update")
                      }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ $store.state.assignmentUser.updateDate }}
                      {{ $t("modifyby") }}
                      {{ $store.state.assignmentUser.updateBy }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <label for="coursename" class="font-style">{{
                      $t("coursename")
                    }}</label>
                    <v-text-field
                      v-model="$store.state.assignmentUser.assignmentCourseName"
                      :rules="editcourseRules"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="description" class="font-style">{{
                      $t("description")
                    }}</label>
                    <v-text-field
                      v-model="
                        $store.state.assignmentUser.assignmentCourseDescription
                      "
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
                <v-row>
                  <v-col>
                    <p
                      class="text-left font-style mb-1"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div class="drop mt-2">
                      <v-card
                        width="270"
                        height="200"
                        style="
                          border: 2px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.courseFile.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropCourseImg($event)"
                      >
                        <v-img
                          :src="$store.state.assignmentUser.courseImgFile"
                          @click="$refs.courseFile.click()"
                          @mouseover="
                            $store.state.assignmentUser.courseImgFile
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            $store.state.assignmentUser.courseImgFile
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="200"
                          height="155"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="$store.state.assignmentUser.courseImgFile"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#FF6060"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="
                                    ($store.state.assignmentUser.courseImgFile =
                                      null),
                                      (courseFile = null),
                                      ($store.state.assignmentUser.fileNameOfCourseImg =
                                        null)
                                  "
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>
                        <v-img
                          v-if="!$store.state.assignmentUser.courseImgFile"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.courseFile.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="courseFile"
                          accept="image/*"
                          @change="uploadCourseImg($event, 1)"
                        />
                        <p
                          v-show="!$store.state.assignmentUser.courseImgFile"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!$store.state.assignmentUser.courseImgFile"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          font-size: 16px !important;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.courseFile.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p
                        class="mt-3 pr-3"
                        style="color: #777777; font-size: 12px"
                      >
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3">
                <label for="startdate" class="font-style">{{
                  $t("startdate")
                }}</label>
                <v-menu
                  v-model="editvstartdatemenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="$store.state.assignmentUser.videostartdate"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="$store.state.assignmentUser.videostartdate"
                    @input="editvstartDateDis"
                    :allowed-dates="startallowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <div class="d-inline">
                  <label for="enddate" class="font-style">{{
                    $t("enddate")
                  }}</label>
                  <v-menu
                    v-model="editvenddatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="$store.state.assignmentUser.videoenddate"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="$store.state.assignmentUser.videoenddate"
                      @input="editvenddatemenu = false"
                      :allowed-dates="endallowedDates"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col>
                <label for="status" class="font-style">{{
                  $t("status")
                }}</label>
                <v-radio-group
                  row
                  v-model="$store.state.assignmentUser.status"
                  class="activation mt-3"
                  hide-details
                >
                  <v-radio
                    :label="$t('active')"
                    value="1"
                    color="#67AB30"
                    @click="disableNoti($store.state.assignmentUser.status)"
                  ></v-radio>
                  <v-radio
                    :label="$t('inactive')"
                    value="0"
                    color="#67AB30"
                    @click="disableNoti($store.state.assignmentUser.status)"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <div class="editonoffswitch mt-4 ml-5">
                  <input
                    type="checkbox"
                    name="editonoffswitch"
                    class="editonoffswitch-checkbox"
                    id="editmyonoffswitch"
                    checked
                    v-model="$store.state.assignmentUser.viewerstatus"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                  />
                  <label class="editonoffswitch-label" for="editmyonoffswitch">
                    <div class="editonoffswitch-inner"></div>
                    <div class="editonoffswitch-switch"></div>
                  </label>
                </div>
                <div class="classdivider">
                  <v-divider
                    vertical
                    style="
                      border-color: #424242;
                      opacity: 0.2;
                      margin-left: 6.5rem;
                      margin-top: -6rem;
                      min-height: 4em;
                    "
                  ></v-divider>
                </div>

                <div class="labelswitch">
                  <label
                    for="viewersettings"
                    style="font-size: 16px; color: #000000; font-weight: bold"
                    >{{ $t("viewersettings") }}
                  </label>
                </div>
                <div class="labelswitchs">
                  <label for="allowviewersrewind" style="font-size: 14px">
                    {{ $t("allowviewersrewind") }}
                  </label>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col>
                <div class="text-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- Edit Course's -->
                      <v-btn
                        depressed
                        color="secondary"
                        style="font-size: 16px; margin-left: 15px; float: right"
                        class="d-flex select text-capitalize btn_hover_effect"
                        @click="addNew()"
                      >
                        <v-icon style="font-size: 16px; margin: 0px 5px 1px 0px"
                          >mdi-plus-box-outline</v-icon
                        >
                        {{ $t("addvideo") }}
                      </v-btn>

                      <v-btn
                        depressed
                        class="d-flex select btn_hover_effect"
                        style="font-size: 16px; float: right"
                        color="secondary"
                        dark
                        @click="ShowVideoListDialog = true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <!-- addassignmentCourse() -->
                        <v-icon class="pb-1">mdi-folder-search-outline</v-icon>
                        <p class="text-capitalize mb-0">
                          {{ $t("fromlibrary") }}
                        </p>
                      </v-btn>
                    </template>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-progress-circular
              v-if="actionloading"
              indeterminate
              class="loading-circle"
              color="green"
            ></v-progress-circular>
            <div class="mt-4">
              <v-data-table
                :headers="libraryandpcheaders"
                :items="editVideoList"
                hide-default-footer
                class="assign-table"
                disable-pagination
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="text-xs-start" style="width: 90px">
                      {{ item.part }}
                    </td>
                    <td class="text-xs-center">
                      <p
                        style="
                          width: 250px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.title }}
                      </p>
                    </td>
                    <td class="text-xs-start">
                      <p
                        style="
                          width: 250px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.videoName }}
                      </p>
                    </td>
                    <td class="text-xs-center">{{ item.size }}</td>

                    <td class="text-xs-center">{{ item.duration }}</td>

                    <td>
                      <div class="d-flex">
                        <v-btn
                          color="#4FB14E"
                          text
                          style="font-size: 16px"
                          class="text-capitalize"
                          @click="editAssignmentCourseEdit(item, index)"
                        >
                          <v-icon>mdi-square-edit-outline</v-icon>
                          {{ $t("edit") }}
                        </v-btn>
                        <v-btn
                          color="#FF6060"
                          text
                          style="font-size: 16px"
                          class="text-capitalize"
                          @click="checkVideolistDelete(item.no)"
                        >
                          <v-icon>mdi-delete-outline</v-icon>
                          {{ $t("delete") }}
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="mt-5">
              <p style="color: #424242; font-size: 18px; padding: 10px">
                {{ $t("sendnotito") }}
              </p>
              <v-row class="ml-6 mr-15 pb-3" rows="12" sm="12" md="12" lg="12">
              <v-col cols="2">
                <v-card width="100px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                        >
                <v-checkbox
                  class="pb-5"
                  v-model="$store.state.assignmentUser.inbox"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :label="$t('inbox')"
                    color="#a6cc39"
                    hide-details
                ></v-checkbox
              ></v-card>
            </v-col>
              <v-col cols="3"
                >
                <v-card width="200px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                        >
                <v-checkbox
                    class="pb-5"
                    v-model="$store.state.assignmentUser.pushnoti"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    color="#a6cc39"
                   
                    :label="$t('pushnotification')"
                    hide-details
                ></v-checkbox
              ></v-card>
            </v-col>
              
              <v-col cols="2">
                <v-card width="100px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                        >
                <v-checkbox
                 class="pb-5"
                 v-model="$store.state.assignmentUser.line"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    color="#a6cc39"
                    :label="$t('line')"
                    hide-details
                ></v-checkbox>
              </v-card>
              </v-col>
              <v-col
              class="d-flex justify-center"
              >
                <v-card
                  width="250px"
                  height="35px"
                  style="
                    border: 1.5px dashed #c0c0c0;
                    box-shadow: unset;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                <v-row
                    class="d-flex justify-center align-center"
                    no-gutters
                  >
                    <v-col class="d-flex justify-center align-center" cols="4">
                      <v-checkbox
                        class="pb-5"
                        v-model="$store.state.assignmentUser.email"
                    :indeterminate="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    :disabled="
                      $store.state.assignmentUser.status == 0 ? true : false
                    "
                    color="#a6cc39"
                    :label="$t('email')"
                    hide-details
                        @change="handleCheckboxChange"
                      ></v-checkbox>
                    </v-col>
                    <template>
                      <v-col class="d-flex justify-center align-center">
                        <v-row class="d-flex justify-center align-center">
                          <v-radio-group v-model="$store.state.assignmentUser.selectedRadio" class="radio-group" :disable="!$store.state.assignmentUser.email">
                            <v-row rows="12" class="radiobutton">
                              <div class="radio-wrapper">
                             
                             <div v-if="!$store.state.assignmentUser.email" class="radio-placeholder"></div>
                            
                             <v-radio
                               v-else
                               value="thai"
                               class="radio-button"
                             ></v-radio>
                           </div>
                           <v-img
                              src="@/assets/thailandflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            <div class="radio-wrapper ml-2">
                             
                             <div v-if="!$store.state.assignmentUser.email" class="radio-placeholder"> </div>
                            <v-radio
                            v-else
                            class=" radio-button"
                             value="eng"
                            
                              >
                            </v-radio>
                            </div>
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            </v-row>
                            
                          </v-radio-group>
                         
                        </v-row>
                      </v-col>
                    </template>
                   
                    <!-- <template>
                      <v-col class="d-flex justify-center align-center">
                        <v-row class="d-flex justify-center align-center">
                          <v-radio-group v-model="$store.state.assignmentUser.selectedRadio"
                          :indeterminate="
                            $store.state.assignmentUser.status == 0 ? true : false
                          "
                          :disabled="
                            $store.state.assignmentUser.status == 0 ? true : false
                          "
                           class="radio-group">
                            <div class="radio-wrapper">
                             
                              <div v-if="!$store.state.assignmentUser.email" class="radio-placeholder"></div>
                             
                              <v-radio
                                v-else
                                value="thai"
                                class="radio-button"
                              ></v-radio>
                            </div>
                          </v-radio-group>
                          <v-card
                            width="35px"
                            height="22px"
                            style="border-radius: 4px; margin-left: 8px;"
                          >
                            <v-img
                             src="@/assets/thailandflag.svg"
                              width="35"
                              height="22"
                            ></v-img>
                          </v-card>
                        </v-row>
                      </v-col>
                    </template> -->
                    <!-- <template>
                      <v-col class="d-flex justify-center align-center">
                        <v-row class="d-flex justify-center align-center">
                          <v-radio-group v-model="$store.state.assignmentUser.selectedRadio" class="radio-group">
                            <div class="radio-wrapper">
                             
                              <div v-if="!$store.state.assignmentUser.email" class="radio-placeholder"></div>
                             
                              <v-radio
                                v-else
                                value="eng"
                                class="radio-button"
                              ></v-radio>
                            </div>
                          </v-radio-group>
                          <v-card
                            width="35px"
                            height="22px"
                            style="border-radius: 4px; margin-left: 8px;"
                          >
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                            ></v-img>
                          </v-card>
                        </v-row>
                      </v-col>
                    </template> -->
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
              
            </div>

            <!-- Add Quiz in Edit Assignment -->
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  depressed
                  color="secondary"
                  style="font-size: 16px; margin-left: 15px; float: right"
                  class="d-flex select text-capitalize btn_hover_effect"
                  @click="OpenQuizDialog()"
                  :disabled="addQuizDisabled"
                  >{{ $t("addquiz") }}</v-btn
                >
              </v-col>
            </v-row>

            <div class="mt-4">
              <v-data-table
                :headers="quizheaders"
                :items="$store.state.assignmentUser.selectedListQuiz"
                hide-default-footer
                class="assign-table"
                @page-count="actionpageCount = $event"
                :page.sync="actionpage"
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.part="{ index }">{{
                  index + 1
                }}</template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.quizTypeName="{ item }">{{
                  $t(item.quizTypeName.toLowerCase().replace(/[-\/\s]/g, ""))
                }}</template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <div>
                    <v-btn
                      color="#FF6060"
                      text
                      style="font-size: 16px"
                      class="text-capitalize"
                      @click="DeleteQuiz(item)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                      {{ $t("delete") }}
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </div>

            <!-- Add Article in Edit Assignment -->
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  depressed
                  color="secondary"
                  style="font-size: 16px; margin-top: 13px; float: right"
                  class="text-capitalize d-flex select white--text btn_hover_effect"
                  @click="AddArticleDialog = true"
                >
                  <v-icon style="font-size: 16px; margin: 0px 5px 1px 0px"
                    >mdi-plus-box-outline</v-icon
                  >
                  {{ $t("addarticle") }}
                </v-btn>
                <v-btn
                  depressed
                  class="d-flex select btn_hover_effect mr-4"
                  style="font-size: 16px; float: right; margin-top: 13px"
                  color="secondary"
                  dark
                  @click="ShowArticleListDialog = true"
                >
                  <p class="text-capitalize mb-0">
                    <v-icon class="pb-1">mdi-folder-search-outline</v-icon>
                    {{ $t("fromlibrary") }}
                  </p>
                </v-btn>
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="ArticleHeaders"
                :items="articleItem"
                hide-default-footer
                class="assign-table"
                @page-count="actionpageCount = $event"
                :page.sync="actionpage"
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.part="{ index }">{{
                  index + 1
                }}</template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="#4FB14E"
                    text
                    style="font-size: 16px"
                    class="text-capitalize"
                    @click="OpenEditArticleDialog(item)"
                  >
                    <v-icon>mdi-square-edit-outline</v-icon>
                    {{ $t("edit") }}
                  </v-btn>

                  <v-btn
                    color="#FF6060"
                    text
                    style="font-size: 16px"
                    class="text-capitalize"
                    @click="prepareArticleRemove(item)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                    {{ $t("delete") }}
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <!-- End Add Article in Edit -->

            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  depressed
                  color="secondary"
                  style="font-size: 16px; margin-top: 13px; float: right"
                  class="text-capitalize white--text select btn_hover_effect"
                  @click="$router.push('/home/assignment-course/user-list')"
                  >{{ $t("selecteduser") }}</v-btn
                >
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                class="user_tableSelected"
                :headers="userheaders"
                :items="selectedList"
                @page-count="actionpageCount = $event"
                :page.sync="actionpage"
                hide-default-footer
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <v-btn
                      color="#FF6060"
                      text
                      style="font-size: 16px"
                      class="text-capitalize"
                      @click="checkUserDelete(item)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                      {{ $t("delete") }}
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
              <v-row justify="end">
                <div class="ma-2 d-flex">
                  <v-pagination
                    circle
                    v-model="actionpage"
                    class="pagination"
                    :length="actionpageCount"
                  ></v-pagination>
                </div>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-5 mt-3">
          <v-spacer></v-spacer>
          <p
            v-if="isValidate"
            style="font-size: 18px; color: red; margin-bottom: -2px"
          >
            Please upload video*&nbsp;&nbsp;&nbsp;
          </p>
          <v-btn
            class="text-capitalize btnfont-style"
            text
            outlined
            @click="
              $store.commit('closeAssignmentEditDialog'),
                cancelAssignmentCourse()
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize select btnfont-style white--text btn_hover_effect"
            color="secondary"
            style="width: 100px"
            @click="twiceUpdate()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete -->
    <v-dialog v-model="deleteassignmentcourse" width="587">
      <v-card>
        <v-card-title
          style="
            font-size: 25px;
            color: #363636;
            height: 84px;
            font-weight: bold;
          "
          class="justify-start"
        >
          <p>{{ $t("deleteconfirm") }}</p>
        </v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="font-size: 20px; color: #363636; text-align: left"
          >
            {{ $t("suredelete") }} "Assignment Course#{{ editData.id }}" ?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            style="
              font-size: 16px;
              color: #424242;
              border: 1px solid #c0c0c0;
              border-radius: 8px;
            "
            width="100"
            text
            @click="deleteassignmentcourse = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize"
            width="100"
            style="
              font-size: 16px;
              background: #ff6060 0% 0% no-repeat padding-box;
              border-radius: 8px;
              color: #ffffff;
            "
            @click="deleteData()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Add Course => edit pc and library -->
    <v-dialog
      v-model="editPcandLibrarydialog"
      persistent
      width="1220"
      scrollable
    >
      <v-card>
        <v-card-title style="font-size: 25px" class="pb-4">
          <v-icon color="#424242" class="mr-2">mdi-plus-box-outline</v-icon>
          {{ $t("addvideo") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="CancelVideo()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px">
          <v-form ref="form3" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <div>
                  <label for="title" class="font-style">{{
                    $t("title")
                  }}</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    id="title"
                    :rules="videoTitleRules"
                    class="rounded-lg"
                    v-model="editfunc.title"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col>
                    <p
                      class="text-left font-style mb-1"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div class="drop">
                      <v-card
                        width="270"
                        height="200"
                        style="
                          border: 2px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          :src="file"
                          @click="$refs.file.click()"
                          @mouseover="
                            file
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            file
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="200"
                          height="155"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="file"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#FF6060"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="file = null"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>
                        <v-img
                          v-if="!file"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!file"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!file"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          font-size: 16px !important;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p
                        class="mt-3 pr-3"
                        style="color: #777777; font-size: 12px"
                      >
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-progress-circular
              v-if="videoloading"
              indeterminate
              class="loading-circle"
              color="green"
            ></v-progress-circular>
            <v-row>
              <v-col cols="12">
                <v-row justify="end" style="margin-right: 1px">
                  <input
                    type="file"
                    accept="video/mp4"
                    class="d-none"
                    ref="video"
                    @change="getVideo"
                  />
                  <v-btn
                    color="secondary"
                    class="text-capitalize white--text select btn_hover_effect"
                    width="150"
                    :disabled="addBtnEnable"
                    style="font-size: 16px"
                    @click="$refs.video.click()"
                    >{{ $t("additem") }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="editvideoHeaders"
                  :items="editVideo.videoList"
                  class="vd_class"
                  hide-default-footer
                >
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.part="{ index }">{{
                    index + 1
                  }}</template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.popupTime="{ item }">
                    <tr>
                      <td>
                        <div style="width: 60px !important; margin-left: 5px">
                          <v-text-field
                            solo
                            class="limited-submit"
                            hide-spin-buttons
                            hide-details
                            value="0"
                            type="number"
                            :class="{ 'red-border': showRedBorder }"
                            v-model="item.popupTime"
                            @input="checkPopUpTime(item.popupTime, item)"
                          ></v-text-field>
                        </div>
                      </td>
                      <td class="pl-3">{{ "Min." }}</td>
                    </tr>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="#FF6060"
                      style="font-size: 16px"
                      class="text-capitalize"
                      text
                      @click="deleteVideoList(item.no)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                      {{ $t("delete") }}
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" v-if="editVideo.showPermission">
                <span class="font-style">{{ $t("assignpartlist") }}</span>
                <v-data-table
                  class="mt-3 vd_class"
                  :items="permissionTable"
                  :headers="editvideoselectedHeader"
                  hide-default-footer
                >
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.permission="{ item }">
                    <v-checkbox
                      color="secondary"
                      v-model="item.permission"
                    ></v-checkbox>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.request="{ item }">
                    <v-checkbox
                      color="secondary"
                      v-model="item.request"
                    ></v-checkbox>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.perDay="{ item }">
                    <div v-if="item.request">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-on="on"
                            v-bind="attrs"
                            v-model="item.selectedDate"
                            append-icon="mdi-calendar-month"
                            solo
                            dense
                            readonly
                            hide-details="auto"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :allowed-dates="allowedPerday"
                          v-model="item.perDay"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            class="font-style"
                            @click="item.menu = false"
                            >{{ $t("cancel") }}</v-btn
                          >
                          <v-btn
                            text
                            class="font-style"
                            color="primary"
                            @click="
                              $refs.menu.save(item.perDay),
                                (item.menu = false),
                                (item.selectedDate = item.perDay)
                            "
                            >{{ $t("ok") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            outlined
            @click="CancelVideo()"
            >{{ $t("cancel") }}</v-btn
          >
          <!-- Add Course's submit video file.. -->
          <v-btn
            class="text-capitalize select btnfont-style white--text btn_hover_effect"
            color="secondary"
            width="120"
            @click="updateVideo()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Course edit pc and library (twiceEdit) -->
    <v-dialog
      v-model="twiceEditPcandLibraryDialog"
      persistent
      width="1220"
      scrollable
    >
      <v-card>
        <v-card-title style="font-size: 25px" class="pb-4">
          <v-icon color="#424242">mdi-square-edit-outline</v-icon>
          {{ $t("editvideo") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="CancelVideoEdit()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px">
          <v-form ref="form4" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <div>
                  <label for="title" class="font-style">
                    {{ $t("title") }}
                    <span style="color: red">*</span>
                  </label>
                  <v-text-field
                    :rules="[
                      () =>
                        !!twiceEditPcandLibrary.title || 'Title is required',
                    ]"
                    outlined
                    dense
                    hide-details="auto"
                    id="title"
                    class="rounded-lg mt-2"
                    v-model="twiceEditPcandLibrary.title"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style mb-1"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div class="drop">
                      <v-card
                        width="290"
                        height="215"
                        style="
                          border: 2px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          :src="twiceEditPcandLibrary.pictureBackground"
                          @click="$refs.file.click()"
                          @mouseover="
                            twiceEditPcandLibrary.pictureBackground
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            twiceEditPcandLibrary.pictureBackground
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="167"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="
                            twiceEditPcandLibrary.pictureBackground != null &&
                            this.isCrop == false
                          "
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="edipreviewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#FF6060"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="
                                    twiceEditPcandLibrary.pictureBackground =
                                      null
                                  "
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>
                        <v-img
                          v-if="
                            twiceEditPcandLibrary.base64 == null &&
                            twiceEditPcandLibrary.pictureBackground == null &&
                            this.isCrop == false
                          "
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-if="
                            twiceEditPcandLibrary.base64 == null &&
                            twiceEditPcandLibrary.pictureBackground == null &&
                            this.isCrop == false
                          "
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-if="
                            twiceEditPcandLibrary.base64 == null &&
                            twiceEditPcandLibrary.pictureBackground == null &&
                            this.isCrop == false
                          "
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          font-size: 16px !important;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p
                        class="mt-3 pr-3"
                        style="color: #777777; font-size: 12px"
                      >
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- End Old ui dialog -->
              </v-col>
            </v-row>
            <v-row>
              <v-progress-circular
                v-if="videoloading"
                indeterminate
                class="loading-circle"
                color="green"
              ></v-progress-circular>
              <v-col cols="12">
                <v-row justify="end" style="margin-right: 1px">
                  <input
                    type="file"
                    accept="video/mp4"
                    class="d-none"
                    ref="video"
                    @change="getVideo"
                  />
                  <v-btn
                    color="#6CC8A0"
                    class="text-capitalize white--text select btn_hover_effect"
                    width="150"
                    :disabled="addBtnEnable"
                    style="font-size: 16px"
                    @click="AddNewItem"
                    >{{ $t("additem") }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="editvideoHeaders"
                  class="vd_class"
                  :items="editVideo.videoList"
                  hide-default-footer
                >
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.part="{ index }">{{
                    index + 1
                  }}</template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.popupTime="{ item }">
                    <tr>
                      <td>
                        <div style="width: 60px !important; margin-left: 5px">
                          <v-text-field
                            solo
                            class="limited-submit"
                            hide-spin-buttons
                            hide-details
                            value="0"
                            type="number"
                            :class="{ 'red-border': showRedBorder }"
                            v-model="item.popupTime"
                            @input="checkPopUpTime(item.popupTime, item)"
                          ></v-text-field>
                        </div>
                      </td>
                      <td class="pl-3">{{ "Min." }}</td>
                    </tr>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="#FF6060"
                      style="font-size: 16px"
                      class="text-capitalize"
                      text
                      @click="deleteVideoList(item.no)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                      {{ $t("delete") }}
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" v-if="editVideo.showPermission">
                <span class="font-style">{{ $t("assignpartlist") }}</span>
                <v-data-table
                  class="vd_class"
                  :items="permissionTable"
                  :headers="editvideoselectedHeader"
                  hide-default-footer
                >
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.permission="{ item }">
                    <v-checkbox
                      color="secondary"
                      v-model="item.permission"
                    ></v-checkbox>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.request="{ item }">
                    <v-checkbox
                      color="secondary"
                      v-model="item.request"
                    ></v-checkbox>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.times="{ item }">
                    <v-text-field
                      v-if="item.request"
                      v-model.number="item.times"
                      type="number"
                      outlined
                      dense
                      :rules="timesRules"
                      hide-details="auto"
                      min="0"
                      style="width: 120px"
                    ></v-text-field>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.perDay="{ item }">
                    <div v-if="item.request">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-on="on"
                            v-bind="attrs"
                            v-model="item.selectedDate"
                            append-icon="mdi-calendar-month"
                            solo
                            dense
                            readonly
                            hide-details="auto"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :allowed-dates="EditallowedPerday"
                          v-model="item.perDay"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            class="font-style"
                            @click="item.menu = false"
                            >{{ $t("cancel") }}</v-btn
                          >
                          <v-btn
                            text
                            class="font-style"
                            color="primary"
                            @click="
                              $refs.menu.save(item.perDay),
                                (item.menu = false),
                                (item.selectedDate = item.perDay)
                            "
                            >{{ $t("ok") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <p
            v-if="isValidateVDO"
            style="font-size: 18px; color: red; margin-bottom: -2px"
          >
            *Please add item*&nbsp;&nbsp;&nbsp;
          </p>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            outlined
            @click="CancelVideoEdit()"
            >{{ $t("cancel") }}</v-btn
          >
          <!-- Edit Course's update video file.. -->
          <v-btn
            class="text-capitalize select btnfont-style white--text btn_hover_effect"
            color="secondary"
            width="120px"
            @click="UpdateVideo()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Success New UI Delete -->
    <v-dialog v-model="successDialog" width="450" style="border-radius: 10px">
      <v-card>
        <v-card-title
          style="
            font-size: 25px;
            color: #363636;
            height: 84px;
            font-weight: bold;
            padding-top: 1rem;
            padding-bottom: 1rem;
          "
          class="justify-center"
        >
          <v-icon style="color: #a6cc39; font-size: 25px; margin: 0px 10px 0px"
            >mdi-checkbox-marked-circle</v-icon
          >
          <p class="mb-0">{{ $t("Coursedeleted") }}</p>
        </v-card-title>
      </v-card>
    </v-dialog>

    <!-- Add & Edit quiz -->
    <v-dialog v-model="openAddQuizDialog" width="650" scrollable persistent>
      <v-card>
        <v-card-title class="justify-start pa-5">
          <span style="color: #363636; font-size: 25px">
            <v-icon style="color: #363636" class="mr-1 mb-2"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t("addquiz") }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeAddQuizDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-2">
              <v-col cols="12" md="2">
                <label for="quiztype" style="color: #363636; font-size: 16px">
                  {{ $t("quiztype") }}
                </label>
              </v-col>
              <v-col cols="12" md="6" class="pt-1 pb-1">
                <v-select
                  :items="$store.state.assignmentUser.QuizTypeItems"
                  v-model="$store.state.assignmentUser.selectedQuizType"
                  return-object
                  item-text="typeName"
                  item-value="quizTypeID"
                  solo
                  dense
                  clearable
                  :label="$t('pleaseselect')"
                  outlined
                  color="#C0C0C0"
                  @click:clear="QuizInfoSelected = false"
                  :menu-props="{ closeOnContentClick: true }"
                >
                  <template #append>
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item
                      @click="SelectQuizType(item)"
                      :disabled="item.disabled"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            $t(
                              item.typeName
                                .toLowerCase()
                                .replace(/[-\/\s]/g, "")
                            )
                          }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    {{
                      $t(item.typeName.toLowerCase().replace(/[-\/\s]/g, ""))
                    }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="tooltip">
                <v-icon
                  style="color: #363636; cursor: pointer"
                  class="mr-1 mb-2"
                  >mdi-chat-alert-outline</v-icon
                >
                <span class="tooltiptext">
                  {{ $t("tooltipalertforquiztype") }}
                </span>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col>
                <div class="mt-1">
                  <v-data-table
                    :headers="addQuizheaders"
                    :items="$store.state.assignmentUser.addQuizItems"
                    hide-default-footer
                    class="assign-table"
                    :search="searchQuiz"
                    @page-count="dialogpageCount = $event"
                    :page.sync="dialogpage"
                    item-key="quizId"
                  >
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template v-slot:item.quizId="{ item }">
                      <v-radio-group
                        v-model="$store.state.assignmentUser.selectedQuiz"
                        name="rowSelector"
                        class="tableRadioBtn"
                        hide-details
                      >
                        <v-radio
                          :key="item.quizId"
                          :value="item.quizId"
                          @click="QuizIsSelected()"
                        />
                      </v-radio-group>
                    </template>
                  </v-data-table>
                  <v-row justify="end">
                    <div class="ma-2 d-flex">
                      <v-pagination
                        circle
                        v-model="dialogpage"
                        class="pagination"
                        :length="dialogpageCount"
                      ></v-pagination>
                    </div>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-3 pb-5">
          <span class="ml-1 mt-3" style="color: #d52a2a; font-size: 16px">{{
            $t("onlyonequizcanbeselected")
          }}</span>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="text-capitalize"
            style="font-size: 16px"
            width="80"
            text
            @click="closeAddQuizDialog()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            depressed
            class="text-capitalize btnfont-style white--text mr-3 btn_hover_effect"
            style="font-size: 16px"
            color="secondary"
            width="80"
            :disabled="!QuizInfoSelected"
            @click="SelectQuizEdit()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
    <SuccessDialog
      :show="addsuccessDialog"
      title="Success"
      text="Adding new Assignment is successful."
      @close="
        (addsuccessDialog = false),
          getAllAssignmentCourseData(),
          GetQuizMasterListShowCountForMap()
      "
    />
    <SuccessDialog
      :show="editsuccessDialog"
      title="Success"
      text="Updating Assignment is successful."
      @close="
        (editsuccessDialog = false),
          getAllAssignmentCourseData(),
          GetQuizMasterListShowCountForMap()
      "
    />
    <v-dialog v-model="confirmDialog" width="587" persistent>
      <v-card>
        <v-card-title style="font-size: 25px" class="justify-start">{{
          $t("deleteconfirm")
        }}</v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="
              font-size: 20px;
              color: #363636;
              opacity: 1;
              text-align: start;
            "
          >
            {{ $t("suredelete") }} ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            style="
              font-size: 16px;
              border-radius: 8px;
              border: 1px solid #c0c0c0;
            "
            width="100"
            text
            @click="confirmDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize"
            width="100"
            style="font-size: 16px; border-radius: 8px; color: #fff"
            color="#FF6060"
            @click="deleteUList()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialogQuiz" width="587" persistent>
      <v-card>
        <v-card-title style="font-size: 25px" class="justify-start">{{
          $t("deleteconfirm")
        }}</v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="
              font-size: 20px;
              color: #363636;
              opacity: 1;
              text-align: start;
            "
          >
            {{ $t("suredelete") }} ?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            style="
              font-size: 16px;
              border-radius: 8px;
              border: 1px solid #c0c0c0;
            "
            width="100"
            text
            @click="(confirmDialogQuiz = false), (remove_article = false)"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize"
            width="100"
            style="font-size: 16px; border-radius: 8px; color: #fff"
            color="#FF6060"
            @click="remove_article ? DeleteArticle() : RemoveQuizEdit()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- cropimage -->
    <v-dialog
      v-model="dialogCrop"
      width="1000px"
      height="800px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 30px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="CancelCrop()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="
              font-size: 16px;
              border-radius: 8px;
              background-color: #a6cc39 !important;
              color: #ffffff !important;
            "
            width="110"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- preview img -->
    <v-dialog
      v-model="editpreviewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="editpreviewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="twiceEditPcandLibrary.base64"
        ></v-img>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="edipreviewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="edipreviewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="twiceEditPcandLibrary.pictureBackground"
        ></v-img>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="file ? file : $store.state.assignmentUser.courseImgFile"
        ></v-img>
      </v-card>
    </v-dialog>
    <!-- popUp Time alert -->
    <v-dialog v-model="popUpTimeExceed" width="355" persistent scrollable>
      <v-card style="overflow: hidden">
        <v-card-title class="p-1">
          <p
            class="mt-0"
            style="
              font-size: 16px;
              color: #363636;
              opacity: 1;
              text-align: start;
            "
          >
            {{ $t("plzspecifyagain") }}
          </p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col style="text-align: center">
              <v-btn
                class="text-capitalize"
                style="font-size: 16px; border-radius: 8px; color: #fff"
                color="#A6CC39"
                @click="popUpTimeExceed = false"
                >{{ $t("ok") }}</v-btn
              ></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SuccessDialog
      :show="saveCoursesToUsersSuccess"
      title="Success"
      text="Assign users many courses successful."
      @close="(saveCoursesToUsersSuccess = false), getAllAssignmentCourseData()"
    />
    <ArticleList
      :show="ShowArticleListDialog"
      @close="(ShowArticleListDialog = false), insertArticleDataToTable()"
    />
    <LibraryVideoList
      :show="ShowVideoListDialog"
      @close="ShowVideoListDialog = false"
    />
    <UserListDialog
      :show="ShowUserListDialog"
      @close="
        (ShowUserListDialog = false),
          (disableUserSelect = true),
          ($store.state.assignmentUser.selectedCourses = [])
      "
      @afterSaveUsers="ManyCourseSaveSuccess()"
    />
    <AddCourseArticle
      :show="AddArticleDialog"
      @close="(AddArticleDialog = false), insertArticleDataToTable()"
    />
    <EditCourseArticle
      ref="edit_article"
      :show="EditArticleDialog"
      @close="(EditArticleDialog = false), insertArticleDataToTable()"
    />
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { mapGetters } from "vuex";
import * as moment from "moment/moment";
import ArticleList from "./ArticleListData.vue";
import AddCourseArticle from "./CourseArticle/AddCourseArticle.vue";
import EditCourseArticle from "./CourseArticle/EditCourseArticle.vue";
import LibraryVideoList from "./VideoListData.vue";
import UserListDialog from "./UserListPopup.vue";
import exceljs from "exceljs";
import { saveAs } from "file-saver";

export default {
  components: {
    VueCropper,
    ArticleList,
    AddCourseArticle,
    EditCourseArticle,
    LibraryVideoList,
    UserListDialog,
  },
  props: {
    // full_Name: String
  },
  data: () => ({
    quizTypes: {
      1: "posttest",
      2: "pretest",
      3: "preposttest",
    },
    saveCoursesToUsersSuccess: false,
    ShowUserListDialog: false,
    selectManyCourseStatus: false,
    disableUserSelect: true,
    //selectedCourses: [],
    ShowVideoListDialog: false,
    courseImgCrop: false,
    courseFileName: null,
    courseFile: null,
    deleteQuizId: null,
    addQuizDisabled: false,
    QuizInfoSelected: false,
    EditArticleDialog: false,
    remove_article_id: 0,
    remove_article: false,
    articleItem: [],
    AddArticleDialog: false,
    ShowArticleListDialog: false,
    popUpTimeExceed: false,
    showRedBorder: false,
    AddCourse_addVid: false,
    AddCourse_editVid: false,
    //addImgUpload: false,
    createdate: moment(new Date().toISOString().substr(0, 10))
      .local()
      .format("DD MMM YYYY"),
    showFilterTools: false,
    edipreviewimg: false,
    editpreviewimg: false,
    previewimg: false,
    fileError: false,
    item_no: 0,
    showEditImgTool: false,
    dialogpageCount: 0,
    dialogpage: 1,
    //quizDialogTitle: "Add Quiz",
    selectedQuiz: null,
    ListQuizItemsForMap: [],
    addQuizItems: [],
    openAddQuizDialog: false,
    courseId: null,
    selectedListQuiz: [],
    UIndex: 0,
    confirmDialog: false,
    confirmDialogQuiz: false,
    alreadyPushPcVideoData: false,
    twiceEditPcandLibraryDialog: false,
    twiceEditPcandLibrary: {},
    index: 0,
    addUser: false,
    editPcandLibrarydialog: false,
    valid: true,
    prohiForward: false,
    allUserList: false,
    mushwatchOrder: false,
    videos: [],
    courseName: "",
    editalluser: false,
    Enable: true,
    btnEnable: false,
    addBtnEnable: false,
    dialogCrop: false,
    videoloading: false,
    actionloading: false,
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    permissionDialog: false,
    permissionMessage: "",
    addsuccessDialog: false,
    editsuccessDialog: false,
    endtime: null,
    time: null,
    starttime: null,
    endmenu: false,
    startmenu: false,
    editendtime: null,
    editstarttime: null,
    editendmenu: false,
    editstartmenu: false,
    breadcrumbs: [
      {
        //image: "house.png"
        sidebar_tilte: "home",
      },
      {
        text: "course", //assignmentcourse
      },
    ],
    deleteassignmentcourse: false,
    vstartContentDate: new Date().toISOString().substr(0, 10),
    vendContentDate: new Date().toISOString().substr(0, 10),
    fromdate: "", //new Date().toISOString().substr(0, 10),
    frommenu_New: false,
    tomenu_New: false,
    startdate_New: "", //new Date().toISOString().substr(0, 10),
    enddate_New: "",
    frommenu: false,
    tomenu: false,
    todate_: "",
    //todate: new Date().toISOString().substr(0, 10),
    startdate: new Date().toISOString().substr(0, 10),
    startdatemenu: false,
    enddatemenu: false,
    endDateMenu: false,
    startDateMenu: false,
    editstartdate: new Date().toISOString().substr(0, 10),
    editstartdatemenu: false,
    editenddatemenu: false,
    editvstartdatemenu: false,
    editvenddatemenu: false,
    page: 1,
    pageCount: 0,
    actionpage: 1,
    actionpageCount: 0,
    itemsPerPage: 10,
    paginationCount: [
      { title: 10 },
      { title: 20 },
      { title: 30 },
      { title: 50 },
      { title: 100 },
    ],
    items: [],
    dialog: false,
    successDialog: false,
    message: "",
    search: null,
    searchQuiz: null,
    editData: {},
    linkUrl: "",
    buttonName: "",
    description: "",
    status: "1",
    videoData: [],
    viewassignmentcoursedialog: false,
    assignmentID: 0,
    loading: false,
    editfunc: {
      description: "",
      title: "",
      no: 0,
    },
    editVideo: {
      title: "",
      description: "",
      startDateMenu: false,
      startContentDate: null,
      enddatemenu: false,
      endContentDate: null,
      fileError: false,
      allowDownload: false,
      edit: [],
      videoList: [],
      showedPermissionIndex: 0,
      showPermission: false,
    },

    isCrop: false,
    videoDataTemp: [],
    permissionTable: [],
    dpSDate: "",
    dpEDate: "",
    isValidate: false,
    inbox: false,
    pushnoti: false,
    viewerstatus: true,
    email: false,
    isValidateVDO: false,
    ActiveNumberOfFilesCount: 0,
    PricingData: [],
    full_Name: String,
    actualduration: "",
    FlagCheck: false,
  }),
  mounted() {
    // let year = new Date().getFullYear();
    // let month =
    //   new Date().getMonth() + 1 <= 9
    //     ? "0" + parseInt(new Date().getMonth() + 1)
    //     : new Date().getMonth() + 1;
    // let day =
    //   new Date().getDate() <= 9
    //     ? "0" + parseInt(new Date().getDate())
    //     : new Date().getDate();
    // this.startDateDis(`${year}-${month}-${day}`);
    this.getUserData();
    this.getQuizTypes();
    this.getCompanyArticleList();
    this.getLibraryTopics();
    this.getAllAssignmentCourseData();
    this.$store.commit("changeState");
    this.getPricingData();
    this.getCountForEmployee();
    this.GetQuizMasterListShowCountForMap();
    this.$store.state.assignmentUser.viewerstatus = true;
    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    if (auth) {
      this.full_Name = auth.fullName;
    }
    this.insertArticleDataToTable(); //for data to react
    this.checkDisabledAddQuiz();
  },

  watch: {
    "$store.state.assignmentUser.selectedCourses"() {
      // Handle the selection change
      this.checkUserStartSelect();
    },
  },

  computed: {
    ...mapGetters([
      "selectedvideoList",
      "selecteduserList",
      "assignmentCourse",
      "pcVideoData",
      "editassignment",
      "editVideoList",
    ]),
    customSelectedVideo: {
      get() {
        return this.$store.getters.customSelectedVideo;
      },
      set(e) {
        return this.$store.commit("addCustomSelectedVideo", e);
      },
    },
    openEditDialog: {
      get() {
        return this.$store.getters.editassignmentcoursedialog;
      },
      set() {
        this.$store.commit("closeAssignmentEditDialog");
      },
    },
    editvideoselectedHeader() {
      return [
        {
          text: this.$t("name"),
          value: "fullName",
        },
        {
          text: this.$t("viewpermission"),
          value: "permission",
        },
        {
          text: this.$t("request"),
          value: "request",
        },
        // {
        //   text: "REPEAT",
        //   value: "times"
        // },
        {
          text: this.$t("expiredate"),
          value: "perDay",
        },
      ];
    },
    editvideoHeaders() {
      return [
        {
          text: "#",
          sortable: false,
          value: "part",
        },
        {
          text: this.$t("filenameL"),
          sortable: false,
          value: "videoName",
        },
        {
          text: this.$t("setPopupTime"),
          sortable: false,
          value: "popupTime",
        },
        {
          text: this.$t("time"),
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("size"),
          sortable: false,
          value: "size",
        },
        {
          text: this.$t("action"),
          sortable: false,
          value: "actions",
          align: "center",
        },
      ];
    },
    openAddDialog: {
      get() {
        this.resVideoForShowInTable();
        return this.$store.getters.newassignmentDialog;
      },
      set() {
        this.$store.commit("closeAssignmentDialog");
      },
    },
    selectedList: {
      get() {
        let temp = this.$store.getters.selecteduserList
          ? this.$store.getters.selecteduserList.map((v, i) => ({
              ...v,
              no: i + 1,
            }))
          : [];
        return temp;
      },
      set() {
        return this.$store.getters.selecteduserList;
      },
    },
    selectedVDList() {
      return this.$store.getters.selectedvideoList;
    },
    courseRules() {
      return [(v) => !!v || "Course Name is required"];
    },
    editcourseRules() {
      return [(v) => !!v || "Course Name is required"];
    },
    videoTitleRules() {
      return [(v) => !!v || "Title is required"];
    },
    libraryandpcheaders() {
      return [
        {
          text: "#",
          value: "part",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("title"),
          align: "left",
          value: "title",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("filenameL"),
          align: "left",
          value: "videoName",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("size"),
          align: "left",
          value: "size",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("time"),
          align: "left",
          value: "duration",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("action"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
          width: "230px",
        },
      ];
    },
    userheaders() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          sortable: false,
          width: "70px",
        },
        {
          text: this.$t("employeeId"),
          align: "left",
          value: "userID", //profileID
          class: "tableColor",
          sortable: false,
          width: "140px",
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "fullName",
          class: "tableColor",
          sortable: false,
          width: "180px",
        },
        {
          text: this.$t("email"),
          align: "left",
          value: "email",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("ph"),
          align: "left",
          value: "mobile",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("group"),
          align: "left",
          value: "department",
          class: "tableColor",
          sortable: false,
          width: "250px",
        },
        {
          text: this.$t("action"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    quizheaders() {
      return [
        {
          text: "#",
          value: "part",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("quizname"),
          align: "left",
          value: "quizName",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("questionsm"),
          align: "left",
          value: "question",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("section"),
          align: "left",
          value: "section",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("quiztype"),
          align: "left",
          value: "quizTypeName",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("action"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
          width: "150px",
        },
      ];
    },
    ArticleHeaders() {
      return [
        {
          text: "#",
          value: "part",
          class: "tableColor",
          sortable: false,
          width: "100px",
        },
        {
          text: this.$t("articletitles"),
          align: "left",
          value: "title",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("action"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
          width: "300px",
        },
      ];
    },
    addQuizheaders() {
      return [
        {
          text: "#",
          value: "quizId",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("quizname"),
          align: "left",
          value: "quizName",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("questionsm"),
          align: "left",
          value: "question",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("section"),
          align: "left",
          value: "section",
          class: "tableColor",
          sortable: false,
        },
      ];
    },
    headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("coursename"),
          align: "left",
          value: "courseName",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("videotitle"),
          align: "left",
          value: "description",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("quizname"),
          align: "left",
          value: "quizName",
          class: "tableColor",
          sortable: false,
        },
        // {
        //   text: this.$t("filesize"),
        //   align: "left",
        //   value: "startTime",
        //   class: "tableColor",
        //   sortable: false,
        // },
        // {
        //   text: this.$t("timemin"),
        //   align: "left",
        //   value: "endTime",
        //   class: "tableColor",
        //   sortable: false,
        // },
        {
          text: this.$t("totaluser"),
          align: "left",
          value: "totalUser",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("startdate"),
          align: "left",
          value: "startDateTime",
          sortable: true,
          class: "tableColor",
        },
        {
          text: this.$t("enddate"),
          align: "left",
          value: "endDateTime",
          sortable: true,
          class: "tableColor",
        },
        // {
        //   text: this.$t("status"),
        //   align: "center",
        //   sortable: false,
        //   value: "endTime",
        //   class: "tableColor"
        // },
        {
          text: this.$t("action"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    todate: {
      get() {
        return this.fromdate;
      },
      set() {
        return this.fromdate;
      },
    },
    editenddate: {
      get() {
        return this.editstartdate;
      },
      set() {
        return this.editstartdate;
      },
    },
    enddate: {
      get() {
        return this.$store.state.assignmentUser.startdate;
      },
      set() {
        return this.$store.state.assignmentUser.startdate;
      },
    },
    allowedStartDates: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedEndDates(val) {
      return val >= this.pcVideoData[this.index].allowedStartDates;
    },
  },
  methods: {
    handleCheckboxChange() {
      console.log("hello",this.$store.state.assignmentUser.email);
      if (this.$store.state.assignmentUser.email) {
        
        this.$store.state.assignmentUser.selectedRadio = 'thai';
        console.log("hello",this.$store.state.assignmentUser.selectedRadio);
      } else {
        this.$store.state.assignmentUser.selectedRadio = null;
        console.log("hello", this.$store.state.assignmentUser.selectedRadio);
      }
    },
    async getUserData() {
      let that = this;

      await that.$axios
        .get(
          `${that.web_urlV6}EmployeeEXT/GetEmployeeExtAllV4?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          if (res.data.status === 0) {
            that.$store.state.assignmentUser.allEmployees = res.data.data.map(
              (v, i) => ({
                ...v,
                no: i + 1,
              })
            );
          }
        })
        .catch(function (error) {
          throw error;
        });
    },
    ManyCourseSaveSuccess() {
      this.saveCoursesToUsersSuccess = true;
    },
    openUserListDialog() {
      this.ShowUserListDialog = true;
    },
    getSelectAll() {
      if (this.$store.state.assignmentUser.selectedCourses.length == 0) {
        this.$store.state.assignmentUser.selectedCourses = this.items;
      } else {
        this.$store.state.assignmentUser.selectedCourses = [];
      }
      this.disableUserSelect =
        this.$store.state.assignmentUser.selectedCourses.length === 0;
    },
    checkUserStartSelect() {
      this.disableUserSelect =
        this.$store.state.assignmentUser.selectedCourses.length === 0;
    },
    async getLibraryTopics() {
      let self = this;
      await self.$axios
        .get(
          `${self.web_url}Topic/GetTopics?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          let libraryItems = res.data.data;
          if (libraryItems.length != 0) {
            self.$store.commit("saveLibraryTopicList", libraryItems);
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    closeAddQuizDialog() {
      this.openAddQuizDialog = false;
      this.$store.state.assignmentUser.selectedQuizType = null;
      this.$store.state.assignmentUser.selectedQuiz = null;
    },
    QuizIsSelected() {
      if (this.$store.state.assignmentUser.selectedQuiz) {
        this.QuizInfoSelected =
          this.$store.state.assignmentUser.selectedQuizType !== null;
      }
    },
    SelectQuizType(v) {
      this.$store.state.assignmentUser.selectedQuizType = v;
      if (this.$store.state.assignmentUser.selectedQuizType) {
        this.QuizInfoSelected =
          this.$store.state.assignmentUser.selectedQuiz !== null;
      }
    },
    async getQuizTypes() {
      let that = this;
      await that.$axios
        .get(`${that.web_urlV4}Quiz/GetQuizType`)
        .then(function (response) {
          if (response.data.status === 0) {
            that.$store.state.assignmentUser.QuizTypeItems =
              response.data.data.map((x) => ({
                ...x,
                disabled: false,
              }));
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    OpenEditArticleDialog(item) {
      const temp = {
        title: item.title,
        wordContent: item.wordContent,
        orgImage: item.orgImage,
        link: item.link,
        pdf_Url: item.pdfUrl,
        pdfName: item.pdfName,
        createBy: item.createBy,
        PDFButtonDisable: item.pdfName === "" ? false : true, //pdf_Url
        file: "",
        assignmentArticleID: item.assignmentArticleID,
        optimizeImage: item.optimizeImage,
      };
      this.$store.commit("saveEditSelectedArticle", temp);
      this.EditArticleDialog = true;
    },
    prepareArticleRemove(item) {
      this.remove_article = true;
      this.confirmDialogQuiz = true;
      this.remove_article_id = item.assignmentArticleID;
    },
    async DeleteArticle() {
      let self = this;
      await self.$axios
        .delete(
          `${self.web_urlV6}AssignmentArticle/DeleteAssignmentArticle?assignmentArticleId=` +
            this.remove_article_id
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.articleItem = [];
            self.$store.commit("removeSelectedArticle");
            self.remove_article = false;
            self.confirmDialogQuiz = false;
            self.remove_article_id = 0;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    insertArticleDataToTable() {
      if (
        Object.keys(this.$store.state.assignment.SelectedArticle).length !== 0
      ) {
        // in requirement it said only one article by course
        this.articleItem = this.$store.state.assignment.SelectedArticle;
      } else {
        this.articleItem = [];
      }
    },
    async getCompanyArticleList() {
      let self = this;

      await self.$axios
        .get(
          `${self.web_urlV6}Contents/GetArticleByCompanyID?CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          if (res.data.data) {
            let ArticleList = res.data.data ? res.data.data : [];
            if (ArticleList.length != 0) {
              self.$store.commit("saveArticleList", ArticleList);
            }
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    timeInSeconds(timeString) {
      const timeParts = timeString.split(":");
      let hours = 0;
      let minutes = 0;
      let seconds = 0;

      if (timeParts.length === 2) {
        // Format: mm:ss
        minutes = parseInt(timeParts[0]);
        seconds = parseInt(timeParts[1]);
      } else if (timeParts.length === 3) {
        // Format: HH:MM:SS
        hours = parseInt(timeParts[0]);
        minutes = parseInt(timeParts[1]);
        seconds = parseInt(timeParts[2]);
      }
      return hours * 3600 + minutes * 60 + seconds;
    },

    checkPopUpTime(popupMin, item) {
      let second = popupMin * 60;
      let videoSeconds = this.timeInSeconds(item.duration);

      if (second > videoSeconds) {
        this.$nextTick(() => {
          this.showRedBorder = true;
          this.popUpTimeExceed = true;
          this.editVideo.videoList.find((x) => x.no === item.no).popupTime = 0;
        });
      } else {
        this.showRedBorder = false;
      }
    },
    clearAll() {
      this.search = null;
      this.dpSDate = "";
      this.dpEDate = "";
      this.getPricingData();
      this.getCountForEmployee();
      this.GetQuizMasterListShowCountForMap();
      this.todate_ = ""; //new Date().toISOString().substr(0, 10);
      this.fromdate = ""; //new Date().toISOString().substr(0, 10);
      this.startdate_New = ""; //new Date().toISOString().substr(0, 10);
      this.enddate_New = ""; //new Date().toISOString().substr(0, 10);
      this.getAllAssignmentCourseData();
      this.$store.commit("changeState");
    },
    ShowAction(v) {
      if (v == "Add") {
        this.OpenAddDialogClick();
        this.searchQuiz = null;
        this.$store.state.assignmentUser.courseId = null;
        this.$store.state.assignmentUser.quizType = [];
        this.$store.state.assignmentUser.selectedListQuiz = [];
        this.$store.state.assignmentUser.selectedQuiz = null;
        this.$store.state.assignmentUser.selectedQuizType = null;
        this.checkDisabledAddQuiz();
        this.$store.commit("changeEditAssignment", false);
      }
    },
    disableNoti(v) {
      if (v == 0) {
        this.$store.state.assignmentUser.pushnoti = false;
        this.$store.state.assignmentUser.email = false;
        this.$store.state.assignmentUser.selectedRadio = null;
        this.$store.state.assignmentUser.line = false;
        this.$store.state.assignmentUser.inbox = false;
        this.$store.state.assignmentUser.viewerstatus = true;
      }
    },
    DeleteQuiz(item) {
      this.confirmDialogQuiz = true;
      this.deleteQuizId = item.quizId;
    },
    OpenQuizDialog() {
      this.GetQuizMasterListShowCount();
      this.openAddQuizDialog = true;
      this.QuizInfoSelected = false;

      // //handle quiz type selecting...
      this.checkDisabledQuizType();

      //In R19 remove Edit Quiz button feature
      // if (value == 1) {
      //   this.quizDialogTitle = "addquiz";
      //   this.openAddQuizDialog = true;
      // } else {
      //   this.quizDialogTitle = "Edit Quiz";
      //   this.openAddQuizDialog = true;
      // }
    },
    OpenAddDialogClick() {
      let checkPermission = this.check_Permissions("course");

      if (checkPermission) {
        if (this.ActiveNumberOfFilesCount < this.PricingData.numberOfFilesId) {
          this.$store.commit("openAssignmentDialog");
        } else {
          alert(
            "Unable to add new ones because the number of files has reached the limit. Please contact Seedkm administrator to enhance usage!"
          );
        }
      } else {
        this.permissionDialog = true;
        this.permissionMessage = "You don't have permission to add course.";
      }
    },
    async getPricingData() {
      const res = await this.$axios.get(
        `${this.web_urlV4}Package/GetPackageOfCompanyCustomer?CompanyId=` +
          localStorage.getItem("companyID")
      );
      this.PricingData = res.data.data;
    },
    async getCountForEmployee() {
      let self = this;
      const res = await self.$axios.get(
        `${this.web_url}Company/GetCompanyActiveUserMP3MP4Count?id=` +
          localStorage.getItem("companyID")
      );
      self.ActiveNumberOfFilesCount = res.data.data[0].numberOfFiles;
    },
    deleteVideoList(id) {
      this.editVideo.videoList.splice(
        this.editVideo.videoList.findIndex((item) => item.no === id),
        1
      );
      this.addBtnEnable = false;
    },
    videoEditallowedSDates: (val) =>
      val >= new Date().toISOString().substr(0, 10),
    videoEditallowedEDates(val) {
      return val >= this.vstartContentDate;
    },
    allowedPerday(val) {
      return val <= this.vendContentDate && val >= this.vstartContentDate;
    },
    EditAssignallowedSDates: (val) =>
      val >= new Date().toISOString().substr(0, 10),
    EditAssignallowedEDates(val) {
      return val >= this.twiceEditPcandLibrary.startContentDate;
    },
    EditallowedPerday(val) {
      return (
        val <= this.twiceEditPcandLibrary.endContentDate &&
        val >= this.twiceEditPcandLibrary.startContentDate
      );
    },
    allowedSDates: (val) => val >= new Date().toISOString().substr(0, 10),
    videoallowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    checkStatus(item) {
      if (item) {
        if (item.length == 0) {
          return "Date Picker";
        } else if (item.length == 1) {
          return "Selected Date";
        } else {
          return "Selected Dates";
        }
      } else {
        return "Date Picker";
      }
    },
    permissionL() {
      let partList = [];
      for (let j of this.permissionTable) {
        partList.push({
          assignmentUserID: j.userID,
          permission: j.permission != undefined ? j.permission : false,
          request: j.request != undefined ? j.request : false,
          times: 1,
          perDay: j.perDay != undefined ? j.perDay : "",
        });
      }
      return partList;
    },
    CancelVideoEdit() {
      this.$refs.file.value = null;
      this.twiceEditPcandLibraryDialog = false;
      this.editVideo.showPermission = false;
      this.permissionTable = [];
      this.editVideo.videoList = [];
      this.showEditImgTool = false;
    },

    dropImg(e) {
      let file = e.dataTransfer.files[0];
      if (!/(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
        alert("Please choose image!");
        return false;
      }

      this.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (f) => {
        let src;
        if (typeof f.target.result === "object") {
          src = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          src = f.target.result;
        }
        this.option.img = src;
        this.dialogCrop = true;
      };
      reader.readAsArrayBuffer(file);
    },
    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      self.fileName = file.name;
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        if (this.courseImgCrop) {
          this.getCoureCoverImgFile(data);
        } else {
          this.getFile(data);
        }
        this.dialogCrop = false;
      });
    },
    async getFile(e) {
      this.file = e;
      this.twiceEditPcandLibrary.pictureBackground = e;
      this.twiceEditPcandLibrary.base64 = e;

      this.CancelCrop();
      this.showEditImgTool = false;
    },

    async getCoureCoverImgFile(e) {
      this.$store.state.assignmentUser.courseImgFile = e;

      this.CancelCrop();
      this.showEditImgTool = false;
      this.courseImgCrop = false; //set false after cropping image
    },

    dropCourseImg(e) {
      let file = e.dataTransfer.files[0];
      if (!/(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
        alert("Please choose image!");
        return false;
      }

      this.$store.state.assignmentUser.fileNameOfCourseImg = file.name;
      this.courseImgCrop = true;
      let reader = new FileReader();
      reader.onload = (f) => {
        let src;
        if (typeof f.target.result === "object") {
          src = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          src = f.target.result;
        }
        this.option.img = src;
        this.dialogCrop = true;
      };
      reader.readAsArrayBuffer(file);
    },
    uploadCourseImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      self.$store.state.assignmentUser.fileNameOfCourseImg = file.name;
      self.courseImgCrop = true;
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },

    // Add Course's video new video file add.......
    addNew() {
      this.twiceEditPcandLibrary.pictureBackground = "";
      this.twiceEditPcandLibrary.base64 = "";
      this.file = "";
      this.showEditImgTool = false;

      this.AddCourse_addVid = true;

      if (
        this.ActiveNumberOfFilesCount + this.editVideoList.length <
        this.PricingData.numberOfFilesId
      ) {
        this.editPcandLibrarydialog = true;
        this.AddCourse_addVid = true;
      } else {
        alert(
          "Unable to add new ones because the number of files has reached the limit. Please contact Seedkm administrator to enhance usage!"
        );
      }
    },
    OnChangeSDate() {
      this.dpSDate = this.fromdate + " 00:00";
      this.dpEDate = this.todate + " 23:59";
      this.getAllAssignmentCourseData();
    },
    viewPermission() {
      this.editVideo.showPermission = true;
    },

    // Add Course's video table edit data bind.......
    editForVideo(item) {
      let checkBlob = null;

      this.showEditImgTool = false;
      this.AddCourse_addVid = false; //edit video

      this.editfunc.title = item.title;
      this.editVideo.videoList = [];
      this.editfunc.no = item.no;
      this.editfunc.description = item.description;
      let startdate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      let enddate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.vstartContentDate =
        item.startContentDate != undefined
          ? item.startContentDate.substring(0, 10)
          : startdate;
      this.vendContentDate =
        item.endContentDate != undefined
          ? item.endContentDate.substring(0, 10)
          : enddate;
      //when add from "add new" pictureFile has data
      //when add from "from library" pictureFile has no data
      //only blob type data is saved in pictureFile
      //so when data pass to edit blob type is a must for "this.file" data

      checkBlob = item.pictureBackground.split(":");
      if (checkBlob[0] == null) {
        this.file = "";
      } else if (checkBlob[0] == "data") {
        this.file = item.pictureBackground; // this is for from add new condition...
      } else if (item.pictureFile != "") {
        this.file = item.pictureFile;
      } else {
        this.file = item.pictureBackground;
      }
      this.thumbnail = item.imgBase64; //image cover pic base64

      this.permissionTable = this.selectedList;
      this.editVideo.videoList.push({
        videoName: item.videoName,
        videoFile: item.videoFile,
        part: parseInt(this.editVideo.videoList.length + 1), //"Part " +
        duration: item.duration,
        popupTime: item.popupTime,
        size: item.size,
      });
      this.editPcandLibrarydialog = true; //move from above..
      this.addBtnEnable = this.editVideo.videoList.length > 0;
    },

    // Edit Course's video table edit data bind........
    editAssignmentCourseEdit(e, i) {
      let checkBlob = null;
      this.AddCourse_addVid = false; //edit video
      this.showEditImgTool = false;

      this.index = i;
      this.twiceEditPcandLibrary = {};
      this.editVideo.videoList = [];
      let startdate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      let enddate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      if (e.startContentDate != null) {
        startdate = e.startContentDate.substring(0, 10);
      }
      if (e.endContentDate != null) {
        enddate = e.endContentDate.substring(0, 10);
      }

      checkBlob = e.pictureBackground.split(":");
      let result;
      if (checkBlob[0] == null) {
        result = "";
      } else if (checkBlob[0] == "data") {
        result = e.pictureBackground; // this is for from add new condition...
      } else if (e.pictureFile != "") {
        result = e.pictureFile;
      } else {
        result = e.pictureBackground;
      }

      let temp = {
        id: e.id,
        part: e.part,
        assignmentCourseID: e.assignmentCourseID,
        videoName: e.videoName,
        title: e.title,
        oldID: 0,
        oldVideoID: e.oldVideoID,
        description: e.description,
        startDateMenu: false,
        startContentDate: startdate,
        endContentDate: enddate,
        endDateMenu: false,
        pictureBackground: result,
        // pictureBackground:
        //   e.pictureBackground != "" ? e.pictureBackground : null,
        allowDownload: e.allowDownload,
        imgBase64: e.thumbnail != undefined ? e.thumbnail : "",
        size: e.size,
        duration: e.duration,
        popupTime: e.popupTime,
        videoFile: e.videoFile,
      };
      this.editVideo.videoList.push({
        id: e.id,
        title: e.title,
        videoName: e.videoName,
        videoFile: e.videoFile,
        part: e.part, //"Part " + parseInt(this.editVideo.videoList.length + 1)
        duration: e.duration,
        popupTime: e.popupTime,
        size: e.size,
      });
      this.addBtnEnable = this.editVideo.videoList.length > 0;
      if (e.permissionData != undefined) {
        if (e.permissionData.length > 0) {
          let perList = [];
          for (let j of e.permissionData) {
            let found = this.selectedList.find(
              (c) => c.userID == j.assignmentUserID
            );
            if (found != undefined) {
              perList.push({
                userID: found.userID,
                fullName: found.fullName,
                permission: j.permission,
                request: j.request,
                times: j.times,
                selectedDate: j.perDay == null ? null : j.perDay.split("T")[0],
                perDay: j.perDay,
              });
            }
          }
          this.permissionTable = perList;
        }
      } else {
        this.permissionTable = this.selectedList;
      }
      this.twiceEditPcandLibrary = temp;

      this.twiceEditPcandLibraryDialog = true;
    },

    perList() {
      let partList = [];
      for (let j of this.selectedList) {
        partList.push({
          assignmentUserID: j.userID,
          permission: j.permission != undefined ? j.permission : false,
          request: j.request != undefined ? j.request : false,
          times: 1,
          perDay: j.perDay != undefined ? j.perDay : "",
        });
      }
      return partList;
    },

    // Update video dialog method in Add Course
    async updateVideo() {
      this.FlagCheck = false;
      if (this.$refs.form3.validate()) {
        this.FlagCheck = true;
      } else {
        this.FlagCheck = false;
      }
      if (this.file != null && this.file != "") {
        this.FlagCheck = true;
      } else {
        this.FlagCheck = false;
        alert("Please attach a picture!");
      }

      if (this.$refs.form3.validate() && this.file != "" && this.file != null) {
        this.FlagCheck = true;
      } else {
        this.FlagCheck = false;
      }

      if (this.FlagCheck) {
        if (this.AddCourse_addVid === true) {
          //change picture background to saveable type..
          const res = await this.$axios.post(
            `${this.web_url}File/UploadFileStringBase`,
            {
              base64: this.twiceEditPcandLibrary.pictureBackground,
              fileName: this.fileName,
            }
          );

          this.$store.state.assignmentUser.editVideoList.push({
            title: this.editfunc.title,
            assignmentCourseID: this.assignmentID,
            pictureBackground:
              this.twiceEditPcandLibrary.pictureBackground != ""
                ? this.twiceEditPcandLibrary.pictureBackground
                : "",
            imgBase64:
              this.twiceEditPcandLibrary.base64 != ""
                ? this.twiceEditPcandLibrary.pictureBackground
                : "",
            // imgBase64:
            //   this.twiceEditPcandLibrary.base64 != ""
            //     ? this.twiceEditPcandLibrary.base64
            //     : "",
            size: this.editVideo.videoList[0].size,
            videoName: this.editVideo.videoList[0].videoName,
            videoFile: this.editVideo.videoList[0].videoFile,
            duration: this.editVideo.videoList[0].duration,
            popupTime: this.editVideo.videoList[0].popupTime,
            part: this.editVideo.videoList[0].part,
            id: this.editVideo.videoList[0].id,
            no: parseInt(
              this.$store.state.assignmentUser.editVideoList.length + 1
            ),
            pictureFile: res.data,
          });
        } else if (this.AddCourse_addVid === false) {
          //pictures do not get uploaded, direct comes from Library, so there is no data in the below variable
          //picture data is only bind in this.file
          let checkBlob = null;

          checkBlob = this.file.split(":");
          //change picture background to saveable type..it is blob
          let pic_temp = "";
          if (checkBlob[0] != "https") {
            // if this.file
            const resEdit = await this.$axios.post(
              `${this.web_url}File/UploadFileStringBase`,
              {
                base64: this.file, // change to blob if this.tile is 64 raw
                fileName: this.fileName,
              }
            );
            pic_temp = resEdit.data;
          }

          for (let i of this.$store.state.assignmentUser.editVideoList) {
            if (i.no == this.editfunc.no) {
              i.title = this.editfunc.title;
              i.id = this.editVideo.videoList[0].id;
              i.assignmentCourseID = this.assignmentID;
              i.videoName = this.editVideo.videoList[0].videoName;
              i.description = this.twiceEditPcandLibrary.description;
              i.size = this.editVideo.videoList[0].size;
              i.duration = this.editVideo.videoList[0].duration;
              i.popupTime = this.editVideo.videoList[0].popupTime;
              i.videoFile = this.editVideo.videoList[0].videoFile;
              i.pictureBackground =
                checkBlob[0] == "data" ? this.file : pic_temp; //base64 type
              // i.pictureBackground =
              //   this.twiceEditPcandLibrary.pictureBackground != ""
              //     ? this.twiceEditPcandLibrary.pictureBackground != undefined
              //       ? this.twiceEditPcandLibrary.pictureBackground
              //       : this.file
              //     : "";
              i.imgBase64 = checkBlob[0] == "data" ? this.file : pic_temp; //base64 type
              // i.imgBase64 =
              //   this.twiceEditPcandLibrary.base64 != ""
              //     ? this.twiceEditPcandLibrary.base64
              //     : "";
              i.pictureFile = checkBlob[0] == "https" ? this.file : pic_temp; //1st -> this.file != "" ? this.file : ""; //blob type
              // i.pictureFile = pic_temp != null ? pic_temp : "";
            }
          }
        }

        this.twiceEditPcandLibrary.pictureBackground = ""; //old image keep binding
        this.twiceEditPcandLibrary.base64 = ""; //old image keep binding
        this.editPcandLibrarydialog = false;
        this.editVideo.videoList = [];
        this.file = "";
        this.editfunc.title = "";
        this.editfunc.no = 0;
        this.addBtnEnable = false;
        this.$refs.form3.resetValidation();
        this.$refs.file.value = null;
      }
    },

    // Update video dialog method in Edit Course
    async UpdateVideo() {
      let checkBlob = null;

      checkBlob = this.twiceEditPcandLibrary.pictureBackground.split(":");

      //change picture background to saveable type..it is blob
      //required for in case of addnew data got an update..
      let pic_temp = "";
      if (checkBlob[0] != "https") {
        // if this.file
        const resEdit = await this.$axios.post(
          `${this.web_url}File/UploadFileStringBase`,
          {
            base64: this.twiceEditPcandLibrary.pictureBackground,
            fileName: this.fileName,
          }
        );
        pic_temp = resEdit.data;
      }
      this.FlagCheck = true;
      //Old code
      // if (this.editVideo.videoList.length > 0) {
      //   for (let i of this.editVideoList) {
      //     if (i.id == this.twiceEditPcandLibrary.id) {
      //       i.id = this.twiceEditPcandLibrary.id;//this.editVideo.videoList[0].id;
      //       i.assignmentCourseID = this.twiceEditPcandLibrary.assignmentCourseID;
      //       i.oldID = this.twiceEditPcandLibrary.oldID;
      //       i.oldVideoID = this.twiceEditPcandLibrary.oldVideoID;
      //       i.startContentDate = this.twiceEditPcandLibrary.startContentDate;
      //       i.endContentDate = this.twiceEditPcandLibrary.endContentDate;
      //       i.videoName = this.editVideo.videoList[0].videoName;
      //       i.title = this.twiceEditPcandLibrary.title;
      //       i.description = this.twiceEditPcandLibrary.description;
      //       i.startDateMenu = false;
      //       i.size = this.editVideo.videoList[0].size;
      //       i.duration = this.editVideo.videoList[0].duration;
      //       i.videoFile = this.editVideo.videoList[0].videoFile;
      //       // i.pictureBackground = this.twiceEditPcandLibrary.pictureBackground;
      //       i.pictureBackground = checkBlob[0] == "data" ?
      //                             this.twiceEditPcandLibrary.pictureBackground : pic_temp;
      //       i.pictureFile = checkBlob[0] == "https" ?
      //                       this.twiceEditPcandLibrary.pictureBackground : pic_temp; //1st
      //       i.allowDownload = this.twiceEditPcandLibrary.allowDownload;
      //       // i.imgBase64 = this.twiceEditPcandLibrary.base64;
      //       i.imgBase64 = checkBlob[0] == "data" ? this.twiceEditPcandLibrary.pictureBackground : pic_temp; //base64 type
      //       i.permissionList = this.permissionL();
      //     }
      //   }
      // }

      //new code
      if (this.editVideo.videoList.length > 0) {
        for (let i of this.editVideoList) {
          if (i.part == this.twiceEditPcandLibrary.part) {
            //i.id == this.twiceEditPcandLibrary.id
            //id must same>but not use id> if old id is included cannot update data.
            //data can be updated when it is set as 0.
            i.id = this.editVideo.videoList[0].id;
            i.assignmentCourseID =
              this.twiceEditPcandLibrary.assignmentCourseID;
            i.oldID = this.twiceEditPcandLibrary.oldID;
            i.oldVideoID = this.twiceEditPcandLibrary.oldVideoID;
            i.startContentDate = this.twiceEditPcandLibrary.startContentDate;
            i.endContentDate = this.twiceEditPcandLibrary.endContentDate;
            i.videoName = this.editVideo.videoList[0].videoName;
            i.title = this.twiceEditPcandLibrary.title;
            i.description = this.twiceEditPcandLibrary.description;
            i.startDateMenu = false;
            i.size = this.editVideo.videoList[0].size;
            i.duration = this.editVideo.videoList[0].duration;
            i.popupTime = this.editVideo.videoList[0].popupTime;
            i.videoFile = this.editVideo.videoList[0].videoFile;
            i.pictureBackground =
              checkBlob[0] == "data"
                ? this.twiceEditPcandLibrary.pictureBackground
                : pic_temp;
            i.pictureFile =
              checkBlob[0] == "https"
                ? this.twiceEditPcandLibrary.pictureBackground
                : pic_temp; //1st
            i.allowDownload = this.twiceEditPcandLibrary.allowDownload;
            i.imgBase64 =
              checkBlob[0] == "data"
                ? this.twiceEditPcandLibrary.pictureBackground
                : pic_temp; //base64 type
            i.permissionList = this.permissionL();
          }
        }
      }
      this.isValidateVDO = this.editVideo.videoList.length <= 0;
      if (
        this.twiceEditPcandLibrary.title == "" ||
        this.twiceEditPcandLibrary.pictureBackground == null ||
        this.isValidateVDO === true
      ) {
        this.FlagCheck = false;
      }
      if (this.FlagCheck) {
        this.twiceEditPcandLibraryDialog = false;
        this.editVideo.showPermission = false;
        this.permissionTable = [];
      } else {
        alert("Incomplete information Please enter again!");
      }
      this.editVideo.videoList = [];
      this.twiceEditPcandLibrary.pictureBackground = ""; //old image keep binding
      this.twiceEditPcandLibrary.base64 = ""; //old image keep binding
      this.file = "";
    },

    updateVideoList() {
      let vstartdate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      let venddate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      if (this.endContentDate != null) {
        vstartdate = this.startContentDate.substring(0, 10);
      }
      if (this.endContentDate != null) {
        venddate = this.endContentDate.substring(0, 10);
      }
      for (let v of this.editVideoList) {
        if (this.editfunc.no == v.no) {
          v.title = this.editfunc.title;
          v.startContentDate = this.vstartContentDate;
          v.endContentDate = this.vendContentDate;
          v.description = this.editfunc.description;
          v.pictureBackground =
            this.twiceEditPcandLibrary.pictureBackground != ""
              ? this.twiceEditPcandLibrary.pictureBackground
              : "";
          v.imgBase64 =
            this.twiceEditPcandLibrary.base64 != ""
              ? this.twiceEditPcandLibrary.base64
              : "";
          v.permissionList =
            this.selectedList != undefined ? this.perList() : [];
        }
      }
      this.pcVideoData.push({
        id: this.id,
        assignmentCourseID: 0,
        startDateMenu: false,
        title: this.editfunc.title,
        description: this.editfunc.description,
        size: "5MB",
        allowDownload: this.allowDownload,
        picturebackground: this.picturebackground,
        thumbnail: this.thumbnail,
        videoName: this.songName,
        startdate: vstartdate,
        enddate: venddate,
        videoFile: this.videoFile,
        duration: this.formatDuration(this.time) + "m",
        permissionList: [],
      });
      this.editPcandLibrarydialog = false;
    },

    // chagne to vidoe list dialog in R19
    // addassignmentCourse() {
    //   this.AddCourse_addVid = true;
    //   if (
    //     this.ActiveNumberOfFilesCount + this.editVideoList.length <
    //     this.PricingData.numberOfFilesId
    //   ) {
    //     this.$router.push("/home/library");
    //     this.$store.commit("changeLibraryAction", true);
    //     this.$store.commit(
    //       "saveAssignmentCourse",
    //       this.assignmentCourse.courseName
    //     );
    //   } else {
    //     alert(
    //       "Unable to add new ones because the number of files has reached the limit. Please contact Seedkm administrator to enhance usage!"
    //     );
    //   }
    // },
    AddNewItem() {
      if (
        this.ActiveNumberOfFilesCount + (this.editVideoList.length - 1) <
        this.PricingData.numberOfFilesId
      ) {
        this.$refs.video.click();
      } else {
        alert(
          "Unable to add new ones because the number of files has reached the limit. Please contact Seedkm administrator to enhance usage!"
        );
      }
    },
    async checkUserDelete(item) {
      let self = this;
      self.confirmDialog = true;
      self.UIndex = item.userID;
    },
    // deleteUList() {
    //   this.selectedList.splice(
    //     this.selectedList.findIndex(item => item.userID === this.UIndex),
    //     1
    //   );
    //   for (let v of this.selectedList) {
    //     v.no = v.no - 1;
    //   }
    //   this.confirmDialog = false;
    // },
    deleteUList() {
      this.selecteduserList.splice(
        this.selecteduserList.findIndex((item) => item.userID === this.UIndex),
        1
      );

      this.confirmDialog = false;
    },
    async checkVideolistDelete(e) {
      //selectedVDList
      this.$store.state.assignmentUser.editVideoList.splice(
        this.$store.state.assignmentUser.editVideoList.findIndex(
          (item) => item.no === e
        ),
        1
      );

      this.$store.state.assignmentUser.editVideoList =
        this.$store.state.assignmentUser.editVideoList.map((x, i) => ({
          ...x,
          no: i + 1,
          part: i + 1,
        }));
    },
    resVideoForShowInTable() {
      let res = [];
      let array = this.$store.getters.customSelectedVideo;
      for (const element of array) {
        let vstartdate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        let venddate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        if (element.endContentDate != null) {
          vstartdate = element.startContentDate.substring(0, 10);
        }
        if (element.endContentDate != null) {
          venddate = element.endContentDate.substring(0, 10);
        }
        res.push({
          id: element.id,
          assignmentCourseID: 0,
          startDateMenu: false,
          allowedStartDates: (val) =>
            val >= new Date().toISOString().substr(0, 10),
          title: element.lessonName,
          description: element.wordDescription,
          size: "5MB",
          allowDownload: element.allowDownload,
          picturebackground: element.picturebackground,
          thumbnail: element.thumbnail,
          videoName: element.songName,
          startdate: vstartdate,
          enddate: venddate,
          videoFile: element.videoFile,
          duration: this.formatDuration(element.time) + "m",
        });
      }
      this.$store.state.assignmentUser.pcVideoData = res.map((v, i) => ({
        ...v,
        part: parseInt(i + 1), //"Part " +
      }));
      return res;
    },
    async saveAllUser() {
      if (this.$store.state.assignmentUser.allUserList) {
        const res = await this.$axios.get(
          `${this.web_urlV6}EmployeeEXT/GetEmployeeExtAllV4?id=` +
            localStorage.getItem("companyID")
        );
        this.$store.commit(
          "saveSelecteduserList",
          res.data.data.map((v, i) => ({
            ...v,
            no: i + 1,
          }))
        );
      } else {
        this.$store.commit("removeSelecteduserList");
      }
    },
    async getVideo(e) {
      let self = this;
      this.videoloading = true;
      let filesize = e.target.files[0].size;
      if (filesize <= 157286400) {
        this.addBtnEnable = true;

        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        const video = document.createElement("video");
        video.addEventListener("loadedmetadata", () => {
          self.actualduration = this.formatDuration(video.duration) + "m";
        });
        video.src = URL.createObjectURL(e.target.files[0]);
        const res = await this.$axios.post(
          `${this.web_url}File/UploadFile`,
          formData
        );

        this.editVideo.videoList.push({
          id: 0,
          assignmentCourseID: this.assignmentID,
          oldVideoID: 0,
          videoName: e.target.files[0].name,
          title: "",
          description: "",
          no: parseInt(
            this.$store.state.assignmentUser.editVideoList.length + 1
          ),
          part: parseInt(
            this.$store.state.assignmentUser.editVideoList.length + 1
          ), //"Part " +
          // size: parseFloat(e.target.files[0].size / 1000000).toFixed(2) + "MB",
          size:
            parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2) +
            "MB",
          // duration: 'Hi',
          popupTime: 0,
          duration: self.actualduration,
          videoFile: res.data.data,
          allowDownload: true,
          pictureBackground: "",
        });
      } else {
        alert("Your file size is over 150MB!");
      }

      this.videoloading = false;
      e.target.value = null;
    },

    getVideoDuration(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const media = new Audio(reader.result);
          media.onloadedmetadata = () => resolve(media.duration);
        };
        reader.readAsDataURL(file);
        reader.onerror = (error) => reject(error);
      });
    },
    async checkAssignmentDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("course");
      if (checkPermission) {
        self.deleteassignmentcourse = true;
        self.editData = item;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to delete course.";
      }
    },
    async deleteData() {
      let self = this;
      await this.$axios
        .post(
          `${this.web_url}Assignment/RemoveAssignment?ID=` + this.editData.id
        )
        .then(function (res) {
          self.message = res.data.message;
          self.successDialog = true;
          self.deleteassignmentcourse = false;
          self.getAllAssignmentCourseData();
        })
        .catch(function (err) {
          throw err;
        });
    },
    // viewassignmentcourse(item) {
    //   this.viewassignmentcoursedialog = true;
    //   this.assignmentID = item.id;
    //   this.$store.state.assignmentUser.assignID = item.id;
    //   this.$store.state.assignmentUser.assignmentCourseName = item.courseName;
    //   this.$store.state.assignmentUser.editstartdate = this.editformatDate(
    //     item.startDateTime
    //   );
    //   this.$store.state.assignmentUser.editenddate = this.editformatendDate(
    //     item.endDateTime
    //   );
    //   this.$store.state.assignmentUser.mushwatchOrder = item.mustWatch;
    //   this.$store.state.assignmentUser.prohiForward = item.prohibit;
    //   this.$store.state.assignmentUser.allUserList = item.allUser;
    //   this.editUserList(item.userIDList);
    //   this.$store.state.assignmentUser.pcVideoData = item.videoList.map(
    //     (part, i) => ({
    //       ...part,
    //       part: parseInt(i + 1), //"Part " +
    //     })
    //   );
    //   for (let v of this.$store.state.assignmentUser.pcVideoData) {
    //     v.oldVideoID = v.id;
    //   }
    //   this.$store.commit(
    //     "saveeditVideoList",
    //     this.$store.state.assignmentUser.pcVideoData
    //   );
    // },
    //
    editassignmentcourse(item) {
      console.log("Email.data",item);
      let checkPermission = this.check_Permissions("course");
      if (checkPermission === false) {
        this.permissionDialog = true;
        this.permissionMessage = "You don't have permission to edit course.";
        return false;
      }
      if (item.articleList) {
        this.articleItem = item.articleList;
        this.$store.commit("saveSelectedArticle", item.articleList);
      }
      this.$store.commit("openAssignmentEditDialog");
      this.$store.commit("changeEditAssignment", true);
      this.viewassignmentcoursedialog = false;
      this.assignmentID = item.id;
      this.$store.state.assignmentUser.assignID = item.id;
      this.$store.state.assignmentUser.assignmentCourseName = item.courseName;
      this.$store.state.assignmentUser.videostartdate = this.editformatDate(
        item.startDateTime
      );
      this.$store.state.assignmentUser.videoenddate = this.editformatendDate(
        item.endDateTime
      );
      this.$store.state.assignmentUser.createDate = moment(item.createDate)
        .local()
        .format("DD/MM/YYYY");
      this.$store.state.assignmentUser.updateDate =
        item.updateDate != null
          ? moment(item.updateDate).local().format("DD/MM/YYYY")
          : null;
      this.$store.state.assignmentUser.createBy = item.createBy;
      this.$store.state.assignmentUser.updateBy = item.updateBy;
      this.$store.state.assignmentUser.assignmentCourseDescription =
        item.description;
      this.$store.state.assignmentUser.status = item.status;
      this.$store.state.assignmentUser.mushwatchOrder = item.mustWatch;
      this.$store.state.assignmentUser.prohiForward = item.prohibit;
      this.$store.state.assignmentUser.allUserList = item.allUser;
      this.$store.state.assignmentUser.inbox = item.inboxFlag;
      this.$store.state.assignmentUser.pushnoti = item.pushNotiFlag;
      this.$store.state.assignmentUser.email = item.emailFlag;
      this.$store.state.assignmentUser.line = item.lineFlag;
      this.$store.state.assignmentUser.selectedRadio = item.emailLanguage;
      this.$store.state.assignmentUser.viewerstatus = item.viewerFlag;
      this.editUserList(item.userIDList, item.id);
      this.$store.state.assignmentUser.pcVideoData = item.videoList.map(
        (part, i) => ({
          ...part,
          id: part.id,
          pictureFile: "",
          part: parseInt(i + 1), //"Part " +
        })
      );
      this.ActiveNumberOfFilesCount =
        this.ActiveNumberOfFilesCount - item.videoList.length;

      this.searchQuiz = null;
      this.$store.state.assignmentUser.selectedQuizType = null;
      this.$store.state.assignmentUser.selectedQuiz = null;
      this.$store.state.assignmentUser.selectedListQuiz = [];
      this.$store.state.assignmentUser.courseId = item.id;
      this.$store.state.assignmentUser.quizType = [];
      //course cover img
      this.$store.state.assignmentUser.courseImgFile = item.orgImage;

      // Change to quiz list in R19-1....
      // if (item.quizId != null && item.quizId != undefined) {
      //   let selectedTemp =
      //     this.$store.state.assignmentUser.ListQuizItemsForMap.filter(
      //       (x) => x.quizId == item.quizId
      //     )?.[0];
      //   if (selectedTemp != null && selectedTemp != undefined) {
      //     this.$store.state.assignmentUser.selectedListQuiz.push(selectedTemp);
      //   }
      //   this.$store.state.assignmentUser.quizId = item.quizId;
      //   this.$store.state.assignmentUser.selectedQuiz = item.quizId;
      // }

      //R19-1
      if (item.quizList.length != 0) {
        let temp_selectedQuiz = null;
        let type_temp = null;
        for (let temp of item.quizList) {
          temp_selectedQuiz =
            this.$store.state.assignmentUser.ListQuizItemsForMap.filter(
              (x) => x.quizId == temp.quizId
            )?.[0];
          if (temp_selectedQuiz) {
            type_temp = this.$store.state.assignmentUser.QuizTypeItems.filter(
              (h) => h.quizTypeID === temp.quizTypeID
            )?.[0];
            const q = {
              ...temp_selectedQuiz,
              quizTypeName: type_temp?.typeName,
              quizTypeID: type_temp?.quizTypeID,
            };
            this.$store.state.assignmentUser.selectedListQuiz.push(q);
            this.$store.state.assignmentUser.quizType.push(q?.quizTypeID);
          }
        }
      }
      this.checkDisabledAddQuiz();
      this.checkDisabledQuizType();

      this.$store.commit(
        "saveeditVideoList",
        this.$store.state.assignmentUser.pcVideoData
      );
    },

    async editUserList(v, cid) {
      let templist = [];
      // `${this.web_url}EmployeeEXT/GetEmployeeExtAllV4?id=` +
      //     localStorage.getItem("companyID")
      const res = await this.$axios.get(
        `${
          this.web_url
        }EmployeeEXT/GetEmployeeEXTCourse?Companyid=${localStorage.getItem(
          "companyID"
        )}${cid ? `&Courseid=${cid}` : ""}`
      );
      if (res.data.data.length > 0) {
        let result = res.data.data.map((item) => ({
          ...item,
          //no: i + 1,
        }));
        for (let i of v) {
          let found = result.find((c) => c.userID == i);
          templist.push(found);
        }
        this.selectedList = templist;
        this.$store.commit("saveSelecteduserList", templist);
      }
    },
    editformatDate(v) {
      const [day, month, year] = v.split("/");
      return `${year}-${month}-${day}`;
    },
    editformatendDate(v) {
      const [day, month, year] = v.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDate() {
      const [day, month, year] =
        this.$store.state.assignmentUser.startdate.split("-");
      return `${year}/${month}/${day}`;
    },
    formatEndDate() {
      const [day, month, year] =
        this.$store.state.assignmentUser.enddate.split("-");
      return `${day}/${month}/${year}`;
    },
    //to check course status from admin..
    async checkCourseStatus() {
      let self = this;

      let companyID = localStorage.getItem("companyID");
      let userID = parseInt(localStorage.getItem("UserID"));

      if (companyID == null) {
        return false;
      }
      let request = {
        companyID: companyID,
        userID: userID,
      };
      await self.$axios
        .post(`${self.web_url}EmployeeEXT/CheckPermissionWeb`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            self.selectManyCourseStatus = res.data.data.courseStatus;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async getAllAssignmentCourseData() {
      let self = this;
      self.loading = true;
      self.actionloading = false;
      //let res = null;
      self.isValidate = false;
      self.checkCourseStatus();
      let data = {
        createFromDate: self.fromdate,
        createToDate: self.todate_,
        startDate: self.startdate_New,
        endDate: self.enddate_New,
        companyID: localStorage.getItem("companyID"),
      };

      await self.$axios
        .post(`${self.web_url}Assignment/GetAllAssignment`, data)
        .then(function (response) {
          if (response.data.status == 0) {
            self.items = response.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              startDateTime: moment(v.startDateTime)
                .local()
                .format("DD/MM/YYYY"),
              endDateTime: moment(v.endDateTime).local().format("DD/MM/YYYY"),
              status: v.status === true ? "1" : "0",
            }));
            self.item_no = self.items.length;
          }
          self.loading = false;
          self.$store.state.assignmentUser.selectedCourses = []; //not to effect when delete user from update course..
        })
        .catch(function (err) {
          throw err;
        });
    },

    async saveAssignmentCourse() {
      let self = this;
      self.actionloading = true;
      this.btnEnable = true;
      this.FlagCheck = false;
      let videos = [];
      //for course validate bug, which still show error after save data..
      this.$store.state.assignmentUser.assignmentCourseName =
        this.$store.state.assignmentUser.assignmentCourseName != ""
          ? this.$store.state.assignmentUser.assignmentCourseName
          : null;

      if (this.$refs.form.validate()) {
        this.FlagCheck = true;
      } else {
        this.FlagCheck = false;
      }

      //   if (this.editVideoList.length > 0) {
      //this.isValidate = false;
      //      this.FlagCheck = true;

      for (let v of this.editVideoList) {
        let pic;
        if (v.pictureBackground == undefined) {
          pic = "";
        } else {
          if (v.pictureFile != "") {
            pic = v.pictureFile;
          } else {
            pic = v.pictureBackground;
          }
        }

        videos.push({
          id: 0,
          assignmentCourseID: 0,
          oldID: 0,
          oldVideoID: v.oldVideoID == undefined ? 0 : v.oldVideoID,
          startContentDate:
            v.startContentDate == undefined ? new Date() : v.startContentDate,
          endContentDate:
            v.endContentDate == undefined ? new Date() : v.endContentDate,
          videoName: v.videoName,
          title: v.title == undefined ? "" : v.title,
          description: v.description == undefined ? "" : v.description,
          size: v.size,
          popupTime:
            v.popupTime !== undefined &&
            v.popupTime !== "" &&
            v.popupTime !== null
              ? parseInt(v.popupTime)
              : 0,
          duration: v.duration,
          videoFile: v.videoFile,
          allowDownload: v.allowDownload == undefined ? true : v.allowDownload,
          pictureBackground: pic,
          imgBase64: v.imgBase64 == undefined ? "" : v.imgBase64,
          permissionList: v.permissionList == undefined ? [] : v.permissionList,
        });
      }
      //     } else {
      //      this.isValidate = true;
      //       this.FlagCheck = false;
      //    }

      if (this.$refs.form.validate() && this.isValidate === false) {
        this.FlagCheck = true;
      } else {
        this.FlagCheck = false;
      }
      let addQ_list = [];
      if (self.$store.state.assignmentUser.selectedListQuiz.length != 0) {
        addQ_list = self.prepareQuizSave(
          self.$store.state.assignmentUser.selectedListQuiz
        );
      }
      //prepare course cover image to save
      let base64Res = null;
      if (self.$store.state.assignmentUser.courseImgFile) {
        base64Res = await self.prepareImageFile();
      }

      if (this.FlagCheck) {
        let useridGP = [];
        for (const element of this.selectedList) {
          useridGP.push(parseInt(element.userID));
        }
        const userdata = JSON.parse(
          localStorage.getItem("vivek_authenticated_user_data")
        );
        const memberid = userdata.memberId;
        const data = {
          id: 0,
          courseName: this.$store.state.assignmentUser.assignmentCourseName,
          startDateTime:
            this.$store.state.assignmentUser.startdate + "T00:00:00Z",
          endDateTime: this.$store.state.assignmentUser.enddate + "T00:00:00Z",
          mustWatch: this.$store.state.assignmentUser.mushwatchOrder,
          prohibit: this.$store.state.assignmentUser.prohiForward,
          allUser: this.$store.state.assignmentUser.allUserList,
          userIDList: useridGP,
          description:
            this.$store.state.assignmentUser.assignmentCourseDescription,
          status: this.$store.state.assignmentUser.status == "1",
          videoList: videos,
          loginUser: parseInt(localStorage.getItem("UserID")),
          companyID: localStorage.getItem("companyID"),
          createBy: memberid,
          inboxFlag: this.$store.state.assignmentUser.inbox,
          pushNotiFlag: this.$store.state.assignmentUser.pushnoti,
          emailFlag: this.$store.state.assignmentUser.email,
          lineFlag: this.$store.state.assignmentUser.line,
          emailLanguage: this.$store.state.assignmentUser.selectedRadio,
          viewerFlag: this.$store.state.assignmentUser.viewerstatus,
          //add article id in course save
          assignmentArticleID: self.$store.state.assignment.SelectedArticle[0]
            ?.assignmentArticleID
            ? self.$store.state.assignment.SelectedArticle[0]
                .assignmentArticleID
            : 0,
          //quizId: this.$store.state.assignmentUser.selectedQuiz,//change into new quizlist in R19-1
          quizList: addQ_list, //add quiz list R19-1 feature
          orgImage: base64Res ? base64Res.data : "", // course cover image;
        };
        console.log("AddEmaildata",data);
        await self.$axios
          .post(`${self.web_url}Assignment/AddOrUpdateAssignment`, data)
          .then(function (res) {
            if (res.data.status == 0) {
              self.actionloading = false;
              self.addsuccessDialog = true;
              self.cancelAssignmentCourse();
              self.getCountForEmployee();
              self.getAllAssignmentCourseData();
              self.$store.commit("closeAssignmentDialog");
              self.$store.commit("changeLibraryAction");
              self.$store.state.assignmentUser.editVideoList = [];
            }
          })
          .catch(function (err) {
            throw err;
          });
      }

      this.actionloading = false;
      this.btnEnable = false;
    },
    async prepareImageFile() {
      let self = this;
      const result = await self.$axios.post(
        `${this.web_url}File/UploadFileStringBase`,
        {
          base64: self.$store.state.assignmentUser.courseImgFile,
          fileName: self.$store.state.assignmentUser.fileNameOfCourseImg,
        }
      );

      return result;
    },
    CancelVideo() {
      this.showEditImgTool = false;
      this.$refs.file.value = null;
      this.editPcandLibrarydialog = false;
      this.editVideo.videoList = [];
      this.file = "";
      this.editfunc.title = "";
      this.editfunc.no = 0;
      this.addBtnEnable = false;
      this.$refs.form3.resetValidation();
    },

    async twiceUpdate() {
      let self = this;
      let useridGP = [];
      this.actionloading = true;
      this.isValidateVDO = false;
      let videos = [];
      if (this.selectedList.length > 0) {
        for (const element of this.selectedList) {
          useridGP.push(parseInt(element.userID));
        }
      }
      // if (this.editVideoList.length > 0) {
      //   this.isValidate = false;
      //   this.FlagCheck = true;
      for (let v of this.editVideoList) {
        let pic;
        if (v.pictureBackground == undefined) {
          pic = "";
        } else if (v.pictureFile != "") {
          pic = v.pictureFile;
        } else {
          pic = v.pictureBackground;
        }
        videos.push({
          id: v.id,
          assignmentCourseID: v.assignmentCourseID,
          oldID: 0,
          oldVideoID: v.oldVideoID,
          startContentDate:
            v.startContentDate == undefined ? new Date() : v.startContentDate,
          endContentDate:
            v.endContentDate == undefined ? new Date() : v.endContentDate,
          videoName: v.videoName,
          title: v.title == undefined ? "" : v.title,
          description: v.description == undefined ? "" : v.description,
          size: v.size,
          duration: v.duration,
          popupTime:
            v.popupTime !== undefined &&
            v.popupTime !== "" &&
            v.popupTime !== null
              ? parseInt(v.popupTime)
              : 0,
          videoFile: v.videoFile,
          allowDownload: v.allowDownload,
          pictureBackground: pic,
          imgBase64: v.imgBase64 == undefined ? "" : v.imgBase64,
          permissionList: v.permissionList == undefined ? [] : v.permissionList,
        });
      }
      // } else {
      //   this.isValidate = true;
      //   this.FlagCheck = false;
      // }
      if (this.$refs.form2.validate()) {
        this.FlagCheck = true;
      } else {
        this.FlagCheck = false;
      }
      if (this.$refs.form2.validate() && this.isValidate === false) {
        this.FlagCheck = true;
      } else {
        this.FlagCheck = false;
      }

      let editQ_list = [];
      if (self.$store.state.assignmentUser.selectedListQuiz.length != 0) {
        editQ_list = self.prepareQuizSave(
          self.$store.state.assignmentUser.selectedListQuiz
        );
      }

      //prepare course cover image to save
      let base64Res = null;
      if (self.$store.state.assignmentUser.fileNameOfCourseImg) {
        base64Res = await self.prepareImageFile();
      }

      if (this.FlagCheck) {
        const userdata = JSON.parse(
          localStorage.getItem("vivek_authenticated_user_data")
        );
        const memberid = userdata.memberId;
        const data = {
          id: this.$store.state.assignmentUser.assignID,
          courseName: this.$store.state.assignmentUser.assignmentCourseName,
          startDateTime: this.$store.state.assignmentUser.videostartdate,
          endDateTime: this.$store.state.assignmentUser.videoenddate,
          mustWatch: this.$store.state.assignmentUser.mushwatchOrder,
          prohibit: this.$store.state.assignmentUser.prohiForward,
          allUser: this.$store.state.assignmentUser.allUserList,
          userIDList: useridGP,
          videoList: videos,
          description:
            this.$store.state.assignmentUser.assignmentCourseDescription,
          status: this.$store.state.assignmentUser.status == "1",
          loginUser: parseInt(localStorage.getItem("UserID")),
          companyID: localStorage.getItem("companyID"),
          updateBy: memberid,
          inboxFlag:
            this.$store.state.assignmentUser.inbox != null
              ? this.$store.state.assignmentUser.inbox
              : false,
          pushNotiFlag:
            this.$store.state.assignmentUser.pushnoti != null
              ? this.$store.state.assignmentUser.pushnoti
              : false,
          emailFlag:
            this.$store.state.assignmentUser.email != null
              ? this.$store.state.assignmentUser.email
              : false,
          emailLanguage:
            this.$store.state.assignmentUser.selectedRadio !=null
               ? this.$store.state.assignmentUser.selectedRadio
               : null,
          lineFlag:
            this.$store.state.assignmentUser.line != null
              ? this.$store.state.assignmentUser.line
              : false,
          viewerFlag:
            this.$store.state.assignmentUser.viewerstatus != null
              ? this.$store.state.assignmentUser.viewerstatus
              : false,
          assignmentArticleID: self.$store.state.assignment.SelectedArticle[0]
            ?.assignmentArticleID
            ? self.$store.state.assignment.SelectedArticle[0]
                .assignmentArticleID
            : 0,
          quizList: editQ_list, //add quiz list R19-1 feature
          orgImage: base64Res
            ? base64Res.data
            : self.$store.state.assignmentUser.courseImgFile, // course cover image;
        };
        await self.$axios
          .post(`${self.web_url}Assignment/AddOrUpdateAssignment`, data)
          .then(function (res) {
            if (res.data.status == 0) {
              self.actionloading = false;
              self.editsuccessDialog = true;
              self.$store.commit("closeAssignmentEditDialog");
              self.$store.commit("saveeditVideoList", null);
              self.cancelAssignmentCourse();
              self.getCountForEmployee();
              self.getAllAssignmentCourseData();
              self.twiceEditPcandLibraryDialog = false;
              self.$store.commit("removeSelectedArticle");
              self.$store.commit("removeEditSelectedArticle");
              self.$store.commit("removeSelecteduserList");
            }
          })
          .catch(function (err) {
            throw err;
          });
      }
      this.actionloading = false;
    },

    prepareQuizSave(list) {
      let result = [];
      for (let item of list) {
        let obj = {
          quizId: item.quizId,
          quizName: item.quizName,
          quizTypeID: item.quizTypeID,
        };
        result.push(obj);
      }
      return result;
    },

    StartDateDisabled_New() {
      this.frommenu_New = false;
      if (this.enddate_New == "") {
        this.enddate_New = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      if (this.startdate_New > this.enddate_New) {
        this.enddate_New = this.startdate_New;
      }
    },
    startDateDisabled() {
      this.frommenu = false;
      if (this.todate_ == "") {
        this.todate_ = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      if (this.fromdate > this.todate_) {
        this.todate_ = this.fromdate;
      }
    },
    allowedDatesEnds(val) {
      return val >= this.fromdate;
    },
    allowedEndDates_New(val) {
      return val >= this.startdate_New;
    },

    endDateDisabled() {
      if (this.fromdate == "") {
        this.fromdate = this.todate_;
      }
    },
    allowedDatesEnd(val) {
      return val >= this.endDate;
    },
    startallowedDates: (val) =>
      new Date(val).getTime() + 86400000 >= Date.now(),
    endallowedDates(val) {
      return val >= this.editenddate;
    },
    editcalcRes(e) {
      this.editenddate = e;
    },
    editstartDateDis(selecteddate) {
      this.editcalcRes(selecteddate);
      this.editstartdatemenu = false;
      this.endallowedDates.editstartdate = selecteddate;
      if (this.editstartdate < this.endallowedDates.editstartdate) {
        this.editstartdate = this.endallowedDates.editstartdate;
      }
    },
    editvstartDateDis(selecteddate) {
      this.editcalcRes(selecteddate);
      this.editvstartdatemenu = false;
      this.endallowedDates.editstartdate = selecteddate;
      if (this.editstartdate < this.endallowedDates.editstartdate) {
        this.editstartdate = this.endallowedDates.editstartdate;
      }
      if (
        this.$store.state.assignmentUser.videostartdate >
        this.$store.state.assignmentUser.videoenddate
      ) {
        this.$store.state.assignmentUser.videoenddate = selecteddate;
      }
    },
    //Add
    newallowedDates: (val) => new Date(val).getTime() + 86400000 >= Date.now(),
    newstartDateDis(selecteddate) {
      this.newcalcRes(selecteddate);
      this.startdatemenu = false;
      this.newendallowedDates.$store.state.assignmentUser.startdate =
        selecteddate;
      if (
        this.$store.state.assignmentUser.startdate <
        this.newendallowedDates.$store.state.assignmentUser.startdate
      ) {
        this.$store.state.assignmentUser.startdate =
          this.newendallowedDates.$store.state.assignmentUser.startdate;
      }
    },
    newendallowedDates(val) {
      return val >= this.$store.state.assignmentUser.enddate;
    },
    newcalcRes(e) {
      this.$store.state.assignmentUser.enddate = e;
    },
    cancelAssignmentCourse() {
      this.openAddDialog = false;
      this.isValidate = false;
      this.actionloading = false;
      this.viewassignmentcoursedialog = false;
      this.editassignmentcoursedialog = false;
      this.starttime = "";
      this.$store.state.assignmentUser.assignmentCourseName = "";
      this.$store.state.assignmentUser.assignmentCourseDescription = null;
      this.$store.state.assignmentUser.status = "1";
      this.$store.state.assignmentUser.startdate = new Date()
        .toISOString()
        .substr(0, 10);
      this.$store.state.assignmentUser.editstartdate = new Date()
        .toISOString()
        .substr(0, 10);
      this.$store.state.assignmentUser.editenddate = new Date()
        .toISOString()
        .substr(0, 10);
      this.$store.state.assignmentUser.videostartdate = new Date()
        .toISOString()
        .substr(0, 10);
      this.$store.state.assignmentUser.videoenddate = new Date()
        .toISOString()
        .substr(0, 10);
      this.endtime = "";
      this.$store.state.assignmentUser.mushwatchOrder = false;
      this.$store.state.assignmentUser.prohiForward = false;
      this.$store.state.assignmentUser.allUserList = false;
      this.$store.commit("saveSelecteduserList");
      this.$store.commit("removeSelectedvideoList");
      this.$store.commit("changeLibraryAction");
      this.$store.commit("changeEditAssignment", false);
      this.$store.commit("removeCustomSelectedVideo");
      this.$store.state.assignmentUser.enddate = new Date()
        .toISOString()
        .substr(0, 10);
      this.file = "null";
      this.fileName = null;
      this.fileUrl = "";
      this.description = null;
      this.buttonName = null;
      this.linkUrl = null;
      this.pictureBackground = "";
      this.$store.state.assignmentUser.editVideoList = [];
      this.$store.state.assignmentUser.inbox = false;
      this.$store.state.assignmentUser.pushnoti = false;
      this.$store.state.assignmentUser.email = false;
      this.$store.state.assignmentUser.line = false;
      this.$store.state.assignmentUser.selectedRadio = null;
      this.$store.state.assignmentUser.viewerstatus = true;
      this.$store.commit("removeSelectedArticle");
      this.$store.commit("removeEditSelectedArticle");
      this.articleItem = [];
      this.$store.state.assignmentUser.courseImgFile = null;
      this.$store.state.assignmentUser.fileNameOfCourseImg = null;
      this.courseImgCrop = false;
      this.$store.commit("removeEditVideoTitleList");
    },
    async GetQuizMasterListShowCount() {
      let that = this;
      let request = {
        companyID: localStorage.getItem("companyID"),
        courseId: that.$store.state.assignmentUser.courseId,
      };
      await this.$axios
        .post(`${that.web_urlV4}Quiz/GetQuizMasterListShowCount`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.$store.state.assignmentUser.addQuizItems = that.$store.state
              .assignmentUser?.selectedListQuiz[0]?.quizId
              ? response.data.data.filter(
                  (q) =>
                    q.quizId !=
                    that.$store.state.assignmentUser.selectedListQuiz[0].quizId
                )
              : response.data.data;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetQuizMasterListShowCountForMap() {
      let that = this;
      let request = {
        companyID: localStorage.getItem("companyID"),
      };
      await this.$axios
        .post(`${that.web_urlV4}Quiz/GetQuizMasterListShowCountForMap`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.$store.state.assignmentUser.ListQuizItemsForMap =
              response.data.data;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    SelectQuizEdit() {
      let selectedTemp = this.$store.state.assignmentUser.addQuizItems.filter(
        (x) => x.quizId == this.$store.state.assignmentUser.selectedQuiz
      )?.[0];

      const newSelectedQuiz = {
        ...selectedTemp,
        quizTypeID:
          this.$store.state.assignmentUser.selectedQuizType.quizTypeID,
        quizTypeName:
          this.$store.state.assignmentUser.selectedQuizType.typeName,
      };

      if (newSelectedQuiz != undefined) {
        this.$store.state.assignmentUser.selectedListQuiz.push(newSelectedQuiz); //add quiz to table
      }

      let tempQTypeId = newSelectedQuiz?.quizTypeID;
      this.$store.state.assignmentUser.quizType.push(tempQTypeId);

      //clear previous quiz data..
      this.openAddQuizDialog = false;
      this.$store.state.assignmentUser.selectedQuizType = null;
      this.$store.state.assignmentUser.selectedQuiz = null;
      this.checkDisabledAddQuiz();
    },
    checkDisabledAddQuiz() {
      let disabled1 =
        this.$store.state.assignmentUser.quizType.includes(1) &&
        this.$store.state.assignmentUser.quizType.includes(2);
      let disabled2 = this.$store.state.assignmentUser.quizType.includes(3);
      if (disabled1) {
        this.addQuizDisabled = true;
      } else if (disabled2) {
        this.addQuizDisabled = true;
      } else {
        this.addQuizDisabled = false;
      }
    },
    checkDisabledQuizType() {
      //handle quiz type selecting...
      let tempType = this.$store.state.assignmentUser?.quizType.length !== 0;
      if (tempType) {
        let tempList = this.$store.state.assignmentUser.QuizTypeItems;

        for (let item of this.$store.state.assignmentUser.quizType) {
          if (item === 1) {
            this.$store.state.assignmentUser.QuizTypeItems = tempList.map(
              (a) => ({
                ...a,
                disabled: a.quizTypeID !== 2,
              })
            );
            this.$store.state.assignmentUser.selectedQuizType =
              this.$store.state.assignmentUser.QuizTypeItems.find(
                (b) => b.quizTypeID === 2
              );
          } else if (item === 2) {
            this.$store.state.assignmentUser.QuizTypeItems = tempList.map(
              (a) => ({
                ...a,
                disabled: a.quizTypeID !== 1,
              })
            );
            this.$store.state.assignmentUser.selectedQuizType =
              this.$store.state.assignmentUser.QuizTypeItems.find(
                (b) => b.quizTypeID === 1
              );
          } else {
            this.$store.state.assignmentUser.QuizTypeItems = tempList.map(
              (a) => ({
                ...a,
                disabled: true,
              })
            );
          }
        }
      } else {
        for (let element of this.$store.state.assignmentUser.QuizTypeItems) {
          element.disabled = false;
        }
      }
    },
    RemoveQuizEdit() {
      if (this.deleteQuizId) {
        let deleteQItem =
          this.$store.state.assignmentUser.selectedListQuiz.find(
            (g) => g.quizId === this.deleteQuizId
          );

        const index =
          this.$store.state.assignmentUser.selectedListQuiz.findIndex(
            (g) => g.quizId === this.deleteQuizId
          );
        if (index !== -1) {
          this.$store.state.assignmentUser.selectedListQuiz.splice(index, 1);
        }
        //remove from store quizType
        const indextype = this.$store.state.assignmentUser.quizType.findIndex(
          (g) => g === deleteQItem.quizTypeID
        );
        if (indextype !== -1) {
          this.$store.state.assignmentUser.quizType.splice(index, 1);
        }
        this.checkDisabledAddQuiz();

        this.confirmDialogQuiz = false;
        this.deleteQuizId = null;
        this.$store.state.assignmentUser.selectedQuiz = null;
        this.$store.state.assignmentUser.selectedQuizType = null;
      }
    },
    async exportExcel() {
      const headers = [
        "COURSENAME",
        "VIDEONAME",
        "QUIZNAME",
        // "ROLE", //LEVEL
        "TOTALUSER",
        //"REQUEST",
        "STARTDATE",
        "ENDDATE",
      ];
      const keys = [
        "courseName",
        "videoName",
        "quizName",
        // "level",
        "totalUser",
        //"request",
        "startDateTime",
        // "contentCount",
        "endDateTime",
      ];
      const fileName = `${"Course"}.xlsx`;
      const filterItems = this.items.map((x) => ({ ...x }));
      const data = [];
      data.push(headers);

      for (const item of filterItems) {
        let rowOne = [];

        for (const key of keys) {
          rowOne.push(item[key] || " ");
        }
        rowOne[headers.indexOf("COURSENAME")] = item.courseName;
        if (item.videoList.length != 0) {
          rowOne[headers.indexOf("VIDEONAME")] =
            item.videoList[0].videoName || " ";
        }
        if (item.quizList.length != 0) {
          let quiztypedata = item.quizList[0].quizTypeID
            ? " ( " +
              this.$t(this.quizTypes[item.quizList[0].quizTypeID]) +
              " )"
            : "";
          rowOne[headers.indexOf("QUIZNAME")] =
            item.quizList[0].quizName + quiztypedata || " ";
        }
        rowOne[headers.indexOf("TOTALUSER")] = item.totalUser;
        rowOne[headers.indexOf("STARTDATE")] = item.startDateTime;
        rowOne[headers.indexOf("ENDDATE")] = item.endDateTime;
        data.push(rowOne);

        const maxLength = Math.max(item.videoList.length, item.quizList.length);

        for (let i = 1; i < maxLength; i++) {
          const row = Array.from({ length: headers.length }).fill(" ");

          // Set VIDEONAME if available in videoList
          if (i < item.videoList.length) {
            row[headers.indexOf("VIDEONAME")] =
              item.videoList[i].videoName || " ";
          }

          // Set QUIZNAME if available in quizList
          if (i < item.quizList.length) {
            let quiztype = item.quizList[i].quizTypeID
              ? " ( " +
                this.$t(this.quizTypes[item.quizList[i].quizTypeID]) +
                " )"
              : "";
            row[headers.indexOf("QUIZNAME")] =
              item.quizList[i].quizName + quiztype || " ";
          }

          data.push(row);
        }
      }

      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet("Course", {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });
      worksheet2.getRow(1).eachCell((cell, number) => {
        console.log(number);
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 15,
          color: { argb: "000000" },
          bold: true,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
        x.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );
      worksheet2.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach((column, i) => {
        column.width = headers[i].length + (i <= 10 ? 15 : 105);
      });
      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
  },
};
</script>
<style scoped>
.radio-group {
  display: flex;
  align-items: center;
}
.radiobutton{
  margin-top: -10px;
}
.radio-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

.radio-placeholder,
.radio-button {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.radio-placeholder {
  background-color:#424242;
  opacity: 0.1;
  pointer-events: none; /* Prevent interaction */
}
.editonoffswitch {
  position: relative;
  width: 78px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.editonoffswitch-checkbox {
  display: none;
}

.editonoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #999999;
  border-radius: 20px;
}

.editonoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.editonoffswitch-inner:before,
.editonoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.editonoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #a6cc39;
  color: #ffffff;
}

.editonoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.editonoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 2px;
  background: #ffffff;
  border: 2px solid #999999;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-inner {
  margin-left: 0;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-switch {
  right: 0px;
}
/* end switch */
.labelswitch {
  margin-left: 7.5rem;
  margin-top: -3.5rem;
}
.labelswitchs {
  margin-left: 7.5rem;
  margin-top: 0.5rem;
}
.classdivider {
  margin-top: -7px;
}
.onoffswitch {
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #a6cc39;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.onoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 1px;
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.editonoffswitch {
  position: relative;
  width: 78px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.editonoffswitch-checkbox {
  display: none;
}

.editonoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #999999;
  border-radius: 20px;
}

.editonoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.editonoffswitch-inner:before,
.editonoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.editonoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #a6cc39;
  color: #ffffff;
}

.editonoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.editonoffswitch-switch {
  width: 26px;
  margin: 2px;
  background: #ffffff;
  border: 2px solid #999999;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-inner {
  margin-left: 0;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-switch {
  right: 0px;
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
::v-deep .theme--light.v-label {
  color: #47484b !important;
}
.assign-table {
  table-layout: fixed;
}
::v-deep .v-label {
  font-size: 16px !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #424242 !important;
  opacity: 1;
  text-align: center;
}
::v-deep .vd_class .v-data-table-header th {
  font-size: 17px !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
  text-align: center;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: middle;
  height: 60px;
}
::v-deep .v-dialog {
  border-radius: 16px !important;
}
::v-deep .to-date .v-input__slot {
  width: 155px !important;
}

.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.btnfont-style {
  font-size: 16px;
}
::v-deep .v-input__slot {
  border-radius: 10px !important;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
.row.justify-end {
  margin-top: 2px;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
::v-deep .chk-input .v-input__control {
  align-content: center;
}
::v-deep .chk-input .v-input__slot {
  width: 200px;
}
::v-deep .tableRadioBtn.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}
.activation.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.v-divider--inset:not(.v-divider--vertical) {
  max-width: 10px;
  margin-top: 3.5rem;
  margin-left: 0px;
}
.editbtn {
  width: 40px !important;
  min-width: 40px !important;
  border: 1px solid #f4f4f4;
}
.v-btn {
  border-radius: 8px !important;
}
</style>

<style scoped>
.list-item-padding.v-sheet.v-list {
  border-radius: 8px !important;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  border-radius: 8px !important;
}
.list-item-padding.list-item-padding.theme--light {
  padding: 0 5px !important;
  min-height: 42px !important;
}
::v-deep .list-item-padding.v-list-item:hover {
  background-color: unset !important;
}

@media (min-width: 1264px) {
  .container {
    max-width: unset !important;
    margin-top: 1.5rem !important;
  }
}
.btn_hover_effect {
  /* width: 50%; */
  color: #fff;
  font-size: 11px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.reset-btn {
  width: 13%;
  color: #fff;
  font-size: 11px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  min-width: unset !important;
}
</style>

<style scoped>
::v-deep .assign-tablepc thead th:first-child,
.assign-table thead th:first-child,
.vd_class thead th:first-child,
.user_table thead th:first-child,
.assingnment-course thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
::v-deep .assign-tablepc thead th:last-child,
.assign-table thead th:last-child,
.vd_class thead th:last-child,
.user_table thead th:last-child,
.assingnment-course thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
::v-deep .select {
  color: #fff;
  width: 30%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
}
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
::v-deep
  .user_tableSelected.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: middle;
  height: 60px;
  word-break: break-all !important;
}
::v-deep .limited-submit.v-text-field.v-text-field--solo .v-input__control {
  min-height: 27px;
  padding: 0;
}
::v-deep .limited-submit .v-input__slot {
  border-radius: 4px !important;
}
.red-border {
  border: 1px solid #ff6060;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  color: #424242;
  background-color: #e6e6e6;
  font-size: 16px;
}
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
@media (min-width: 960px) {
  .container {
    max-width: 100%;
  }
}
</style>
