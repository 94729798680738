<template>
  <div>
    <v-dialog v-model="showULDialog" persistent scrollable width="800">
      <v-card style="border-radius: 16px">
        <v-card-title style="font-size: 25px; height: 80px">
          <v-icon style="color: #363636" class="mr-1"
            >mdi-plus-box-outline</v-icon
          >
          {{ $t("userlist") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeULDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 800px">
          <v-row class="mt-2">
            <v-col md="6" lg="6" xl="6" sm="12" class="pb-1">
              <v-text-field
                outlined
                dense
                hide-details
                style="border-radius: 8px; border-color: #c0c0c0"
                clearable
                prepend-inner-icon="mdi-magnify"
                placeholder="search..."
                v-model="search"
                class="mb-1"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="userheaders"
                hide-default-footer
                :items="$store.state.assignmentUser.allEmployees"
                :search="search"
                class="users_table"
                v-model="selected_user"
                @toggle-select-all="getSelectAll"
                item-key="userID"
                show-select
                :loading="loading"
                :loading-text="$t('loadingpleasewait')"
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
              >
              </v-data-table>
            </v-col>
          </v-row>
          <v-row justify="space-between" class="mx-1 mt-2">
            <v-row>
              <v-col cols="auto" class="mr-auto">
                <v-pagination
                  circle
                  v-model="page"
                  class="pagination"
                  :length="pageCount"
                ></v-pagination>
              </v-col>

              <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
                <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="70"
                      v-bind="attrs"
                      v-on="on"
                      style="border: 1px solid #c0c0c0; opacity: 1"
                      class="text-capitalize mt-1"
                      outlined
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in paginationCount"
                      :key="index"
                      @click="itemsPerPage = item.title"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="pt-4 pb-4" style="text-align: right">
              <v-btn
                class="text-capitalize"
                style="
                  font-size: 16px;
                  color: #424242;
                  border: 1px solid #c0c0c0;
                  border-radius: 8px;
                "
                width="100"
                text
                @click="showULDialog = false"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                class="text-capitalize ml-2"
                width="100"
                style="
                  font-size: 16px;
                  background: #a6cc39 0% 0% no-repeat padding-box;
                  border-radius: 8px;
                  color: #ffffff;
                "
                @click="saveManyCourseForEachUserData()"
                >{{ $t("save") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-progress-circular
        v-if="saveloading"
        indeterminate
        class="loading-circle"
        color="green"
      ></v-progress-circular>
    </v-dialog>
  </div>
</template>
  
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      saveloading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      paginationCount: [
        {
          title: 5,
        },
        {
          title: 10,
        },
        {
          title: 20,
        },
        {
          title: 30,
        },
        {
          title: 40,
        },
      ],
      loading: false,
      selected_user: [],
      search: null,
      //userItems: [],
    };
  },

  computed: {
    //...mapGetters(["selecteduserList"]),
    showULDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    userheaders() {
      return [
        {
          text: this.$t("employeeId"),
          align: "left",
          value: "userID",
          class: "tableColor",
          width: "155px"
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "fullName",
          class: "tableColor",
          width: "215px"
        },
        {
          text: this.$t("group"),
          align: "left",
          value: "department",
          class: "tableColor",
        },
      ];
    },
  },

  mounted() {},

  methods: {
    async saveManyCourseForEachUserData() {
      let that = this;
      that.saveloading = true;

      let courseIDList = [];
      let userIDList = [];
      courseIDList = that.$store.state.assignmentUser.selectedCourses.map(
        (item) => item.id
      );
      userIDList = that.selected_user.map((item) => item.userID);

      let data = {
        courseId: courseIDList,
        userId: userIDList,
      };
      await that.$axios
        .post(`${that.web_url}Assignment/AddNewUserToCourse`, data)
        .then(function (response) {
          if (response.data.status == 0) {
            that.disableUserSelect = true;
            that.$store.commit("removeSelecteduserList");
            that.saveloading = false;
            that.closeULDialog();
            that.$emit("afterSaveUsers");
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    getSelectAll() {
      if (this.selected_user == undefined) {
        this.selected_user = this.$store.state.assignmentUser.allEmployees;
      } else if (this.selected_user.length == 0) {
        this.selected_user = this.$store.state.assignmentUser.allEmployees;
      } else {
        this.selected_user = [];
      }
    },
    
    closeULDialog() {
      this.showULDialog = false;
      this.selected_user = [];
      this.$store.state.assignmentUser.selectedCourses = [];
    },
  },
};
</script>
  
<style scoped>
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
.users_table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 16px 10px !important;
}
::v-deep .v-dialog {
  border-radius: 16px;
  overflow-y: hidden !important;
}
::v-deep .v-application .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  color: #424242;
  background-color: #e6e6e6;
  font-size: 16px;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
}
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
</style>
  