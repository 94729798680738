<template>
  <div>
    <v-dialog v-model="showVLDialog" persistent scrollable width="800">
      <v-card style="border-radius: 16px">
        <v-card-title style="font-size: 25px; height: 80px">
          <v-icon style="color: #363636" class="mr-1"
            >mdi-plus-box-outline</v-icon
          >
          {{ $t("videofromlibrary") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="#424242"
            style="border-radius: 8px !important;border: 1px solid rgba(66, 66, 66, 0.05);"
            v-if="cancelCategoryList"
            @click="
              (showVLDialog = false),
                (selectedTopicName = null),
                (videoselected = []),
                (videos = [])
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="closeVLDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
            v-if="showVdoList"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 800px">
          <v-row class="mt-2">
            <v-col md="6" lg="6" xl="6" sm="12" class="pb-1">
              <v-text-field
                outlined
                dense
                hide-details
                style="border-radius: 8px; border-color: #c0c0c0"
                clearable
                prepend-inner-icon="mdi-magnify"
                placeholder="search..."
                v-model="search"
                class="mb-3"
              >
              </v-text-field>
              <span class="pl-2" style="color: #363636; font-size: 20px">{{
                !showVdoList ? $t("category") : selectedTopicName
              }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-1">
              <!-- Topic List -->
              <v-data-table
                v-if="!showVdoList"
                hide-default-header
                :items="$store.state.assignment.LibraryTopicList"
                :search="search"
                hide-default-footer
                class="topic-table"
                item-key="id"
                :headers="topicheaders"
                disable-pagination
                @click:row="onRowClick"
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.orgImage="{ item }">
                  <v-img :src="item.orgImage" class="lessonimg"></v-img>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.topicName="{ item }">
                  <p>{{ item.topicName }}</p>
                </template>
              </v-data-table>
              <!-- Video list -->
              <v-data-table
                v-if="showVdoList"
                :loading="loading"
                :loading-text="$t('loadingpleasewait')"
                hide-default-header
                :items="videos"
                :search="search"
                hide-default-footer
                v-model="videoselected"
                item-key="id"
                show-select
                class="vdo-table"
                :headers="videoheaders"
                disable-pagination
              >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.picturebackground="{ item }">
                  <v-img
                    :src="item.picturebackground"
                    class="lessonimg"
                  ></v-img>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.lessonName="{ item }">
                  <p class="mb-2" style="font-size: 20px; color: #424242">
                    {{ item.lessonName }}
                  </p>
                  <p style="font-size: 16px; color: #424242">
                    {{
                      item.partfileList?.length !== 0
                        ? item.partfileList.length == 1
                          ? item.partfileList.length + " file"
                          : item.partfileList.length + " files"
                        : ""
                    }}
                  </p>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="pt-4 pb-4" style="text-align: right">
              <v-btn
                v-if="cancelCategoryList"
                class="text-capitalize"
                style="
                  font-size: 16px;
                  color: #424242;
                  border: 1px solid #c0c0c0;
                  border-radius: 8px;
                "
                width="100"
                text
                @click="
                  (showVLDialog = false),
                    (selectedTopicName = null),
                    (videoselected = []),
                    (videos = [])
                "
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                v-if="showVdoList"
                class="text-capitalize"
                style="
                  font-size: 16px;
                  color: #424242;
                  border: 1px solid #c0c0c0;
                  border-radius: 8px;
                "
                width="100"
                text
                @click="closeVLDialog()"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                v-if="showVdoList"
                class="text-capitalize ml-2"
                width="100"
                style="
                  font-size: 16px;
                  background: #a6cc39 0% 0% no-repeat padding-box;
                  border-radius: 8px;
                  color: #ffffff;
                "
                @click="saveSelectedVideolist()"
                >{{ $t("update") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    // articleItems: {
    //   type: Array,
    // },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cancelCategoryList: true,
      savedVideoList: false,
      previousVideos: [],
      selectedTopicName: null,
      showVdoList: false,
      videos: [],
      videoselected: [],
      search: null,
      loading: false,
      //selectedItem: [],
    };
  },

  computed: {
    ...mapGetters([
      "editVideoList", // get manual add vdos or already selected vdos from the table (vdoList table data..)
      "EditVideoTitleList", //total videos
    ]),
    showVLDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    topicheaders() {
      return [
        {
          text: "",
          align: "start",
          value: "orgImage",
        },
        {
          text: this.$t("description"),
          align: "left",
          value: "topicName",
        },
      ];
    },
    videoheaders() {
      return [
        {
          text: "",
          align: "left",
          value: "picturebackground",
        },
        {
          text: this.$t("description"),
          align: "unset",
          value: "lessonName",
        },
      ];
    },
  },

  mounted() {},

  methods: {
    async onRowClick(item) {
      let self = this;
      self.showVdoList = true;
      self.savedVideoList = false;
      self.cancelCategoryList = false;
      self.loading = true;

      await self.$axios
        .post(
          `${self.web_url}GuidedLesson/GetGuidedContentTopicIDCustomerWeb`,
          {
            id: item.id,
            companyId: localStorage.getItem("companyID"),
          }
        )
        .then(function (response) {
          if (response.data.status === 0) {
            self.videos = response.data.data;
            self.selectedTopicName = item.topicName;
            self.loading = false;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    closeVLDialog() {
      this.selectedTopicName = null;
      this.videoselected = [];
      this.videos = [];

      if (this.savedVideoList) {
        this.showVLDialog = false;
      } else {
        this.showVdoList = false;
        this.cancelCategoryList = true;
      }
    },
    saveSelectedVideolist() {
      this.showVdoList = false;

      if (this.EditVideoTitleList?.length === 0) {
        this.$store.commit("saveEditVideoTitleList", this.videoselected);
        this.$store.commit("saveeditVideoList", this.videoselected);
      } else {
        //checking already selected data.....
        //and make not to re-select already selected data.....
        let temp_Select = [];
        let temp_main = this.EditVideoTitleList;
        for (let e of this.videoselected) {
          let found = this.EditVideoTitleList.find((v) => v.id !== e.id)
            ? this.EditVideoTitleList.find((v) => v.id !== e.id)
            : null;
          if (found) {
            temp_Select.push(e);
          }
        }
        this.videoselected = temp_Select;
        temp_main.push(temp_Select);
      
        //...............
        this.$store.commit("saveEditVideoTitleList", temp_main);
        this.$store.commit("saveeditVideoList", this.videoselected);
      }
      this.savedVideoList = true;
      this.closeVLDialog();
    },
  },
};
</script>
  
<style scoped>
.lessonimg {
  width: 192px !important;
  height: 100px !important;
  border-radius: 12px;
}
.topic-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 16px 10px !important;
}
::v-deep .v-dialog {
  border-radius: 16px;
  overflow-y: hidden !important;
}
::v-deep .v-application .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep
  .topic-table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #e9f7c6;
  cursor: pointer;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 12px 10px;
}
::v-deep
  .vdo-table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: none;
}
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
::v-deep .topic-table .text-start {
  width: 212px !important;
}
::v-deep .vdo-table .text-left {
  width: 212px !important;
}
::v-deep .vdo-table .text-start {
  width: 50px !important;
}
::v-deep .primary {  
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
</style>
  